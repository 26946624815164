import React, { useState, useEffect } from "react";
import * as ReactDOM from "react-dom/client";
import { obtEmpresaUrl } from "../../../utils/global_functions.js";
import { get, post } from "../../../utils/http.js";
import { ruleEmail } from "../../../assets/validateRules.js";
import { useForm } from "react-hook-form";
import { TablaAdministrativos } from "../../../components/Tablas.jsx";
import Swal from "sweetalert2";

export default function AdministrativosAdminSaturn() {
  const [isLoading, setIsLoading] = useState(true);
  const [administrativos, setAdministrativos] = useState([]);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
    }
  });

  useEffect(() => {
    const cargarDatos = async () => {
      try {
        await obtEmpresaUrl();
        await renderDropdowns();
        cargarAdministrativos();
        setIsLoading(false);
      } catch (error) {
        console.error("Error al cargar datos:", error);
      }
    };

    cargarDatos();
  }, []);

  async function cargarAdministrativos(){
    const peticion = await get("adminSaturn/obtAdministrativos", "");
    if(peticion.statuscode === 200){
      setAdministrativos(peticion.respuesta);
    }
  }

  async function renderDropdowns() {
    let catalogos = await get("adminSaturn/obtCatalogosRol", "");
    if (catalogos.statuscode === 200) {
      let root;
      let opcionDefault = (
        <option value="" selected disabled>
          Seleccione una opción...
        </option>
      );

      const roles = catalogos.respuesta.catRoles.map((rol) => (
        <option value={rol.idCatRol} key={rol.idCatRol}>
          {rol.nombreRol}
        </option>
      ));
      roles.unshift(opcionDefault);
      root = ReactDOM.createRoot(document.getElementById("selectRoles"));
      root.render(roles);

      const empresas = catalogos.respuesta.catEmpresas.map((empresa) => (
        <option value={empresa.idEmpresaSocio} key={empresa.idEmpresaSocio}>
          {empresa.nombre}
        </option>
      ));
      empresas.unshift(opcionDefault);
      root = ReactDOM.createRoot(document.getElementById("selectEmpresas"));
      root.render(empresas);
    }
  }

  const crearAdministrativo = async (data) => {
    const peticion = await post("adminSaturn/crearAdministrativo", {
      nombre: data.nombre,
      correo: data.email,
      idCatRol: data.rol,
      idEmpresaSocio: data.empresa
    });

    if(peticion.statuscode === 200){
      Swal.fire({
        icon: "success",
        title: "Administrativo creado correctamente",
        confirmButtonText: "Aceptar"
      });
      reset({ nombre: "", email: "", rol:  "", empresa: ""});
      cargarAdministrativos();
    }else{
      Swal.fire({
        icon: "error",
        title: "Algo salió mal",
        text: "Inténtelo nuevamente más tarde.",
        confirmButtonText: "Aceptar"
      });
    }
  }

  return (
    <>
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
          <div className="loading-text">Cargando...</div>
        </div>
      )}

      <div className="cont_Info">
        <h1 className="titt">Roles</h1>
        <p className="descripcionClientes">
          Crea accesos al panel de la empresa
        </p>

        <div className="cont_flex cont-form">
          <form onSubmit={handleSubmit(crearAdministrativo)}>
            
            <div className="col3 campo-form">
              <label>Nombre</label>
              <input
                type="text"
                maxLength={50}
                autoComplete="off"
                {...register("nombre", { required: true })}
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^A-Za-zÁÉÍÓÚáéíóúÜüÑñ ]/g, "");
                }}
              />
              {errors.nombre?.type === "required" && (
                <span className="span">Este campo es requerido</span>
              )}
            </div>
            <div className="col3 campo-form">
              <label>E-mail</label>
              <input
                type="text"
                maxLength={50}
                autoComplete="off"
                {...register("email", { required: true, validate: (email) => ruleEmail(email) })}
                
              />
              {errors.email?.type === "required" ? (
                <span className="span">Este campo es requerido</span>
              ) : (
                errors.email?.type === "validate" && (
                  <span className="span">Formato no válido</span>
                )
              )}
            </div>
            <div className="col3 campo-form estado marginEspacio">
              <label>Rol</label>
              <select
                id="selectRoles"
                {...register("rol", { required: true })}
              >
              </select>
              {errors.rol?.type === "required" && (
                <span className="span">Este campo es requerido</span>
              )}
            </div>

            <div className="col3 campo-form estado marginEspacio">
              <label>Empresa</label>
              <select
                id="selectEmpresas"
                {...register("empresa", { required: true })}
              >
              </select>
              {errors.empresa?.type === "required" && (
                <span className="span">Este campo es requerido</span>
              )}
            </div>

            <br/> <br/>
            <div className="">
              <button
                type="submit"
                className="btn btn_morado"
              >
                Crear
              </button>
            </div>
          </form>
        </div>

        <br/>
        <br/>

        <div className="tabla_clientes">
          <TablaAdministrativos data={administrativos} setData={setAdministrativos}/>
        </div>
        <br/>

      </div>
    </>
  );
}