import React, { useState, useEffect, useRef } from "react";
import { obtEmpresaUrl } from "../../../utils/global_functions.js";
import { TablaInvitacionesEjecutivo } from "../../../components/Tablas.jsx";
import { get } from "../../../utils/http.js";
import { Link } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { Cookies } from "react-cookie";

export default function InvitacionesEjecutivo() {
  const [isLoading, setIsLoading] = useState(true);
  const [invitaciones, setInvitaciones] = useState([]);
  const cookies = new Cookies();
  const tokenEjecutivo = useRef(cookies.get("token") ? jwtDecode(cookies.get("token")) : null);

  useEffect(() => {
    const cargarDatos = async () => {
      try {
        await obtEmpresaUrl();

        if(tokenEjecutivo.current){
          const peticion = await get("obtUltimasInvitaciones", tokenEjecutivo.current?.idEmpresa ?? "");

          if(peticion?.statuscode === 200){
            setInvitaciones(peticion.respuesta);
          }
        }
        
        setIsLoading(false);
      } catch (error) {
        console.error("Error al cargar datos:", error);
      }
    };

    cargarDatos();
  }, []);

  return (
    <>
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
          <div className="loading-text">Cargando...</div>
        </div>
      )}

      <div className="cont_Info">
        <h1 className="titt">Invitaciones</h1>
        <p className="descripcionClientes">
          Aquí puedes conocer las capacidades de crédito de tus clientes y la etapa en la que se encuentran en su crédito
        </p>

        <div className="cont_flex cont-form">
          <div className="dosCuttonFormularios">
            <Link
              className="btn btn_bco col3 campo-form "
              to="/ejecutivo/nuevaInvitacion"
            >
              Enviar nueva invitación a cliente
            </Link>
          </div>
        </div>

        <div className="tabla_clientes">
          <TablaInvitacionesEjecutivo invitaciones={invitaciones}/>
        </div>
        <br/>

      </div>
    </>
  );
}