import "../../App.css";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { PasosAseguir } from "../../components/Cards";
import { Link } from "react-router-dom";
import * as ReactDOM from "react-dom/client";
import { TarjetaDescarga } from "../../components/Cards";
import { obtEmpresaUrl, pantallasFlujoBanco } from "../../utils/global_functions";
import { NotasSolicitud, SinNotas } from "../../components/Msjs";
import { get, post, putS3 } from "../../utils/http";

import vector from "../../images/Vector.svg";

export default function DescargarSolicitud() {
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  //Información de la solicitud
  const [descargarSolicitud, setDescargarSolicitud] = useState({});
  //Para enviar el documento
  const [infoVendedorVivienda, setInfoVendedorVivienda] = useState(undefined);
  //Para comprobar el estado del acceso
  const [acceso, setAcceso] = useState(false);
  const [idResPrecalifica, setIdResPrecalifica] = useState(localStorage.getItem("idResPrecalifica"));
  const [idCuestionarioSolicitud, setIdCuestionarioSolicitud] = useState(localStorage.getItem("idCuestionarioSolicitud"));
  const [documentoSubido, setDocumentoSubido] = useState(false);
  // Definimos un estado para idPantallaActual
  const [idPantallaActual, setIdPantallaActual] = useState(null);

  //Obtenemos la pantalla actual
  const obtPantallaActual = async () => {
    let dataPantalla = await get("getPantallaActual", idResPrecalifica);
    if (dataPantalla.statuscode === 200) {
      if (
        dataPantalla.infoProcesoPantalla.idCatPantallas !== null ||
        dataPantalla.infoProcesoPantalla.idCatPantallas !== ""
      ) {
        setIdPantallaActual(dataPantalla.infoProcesoPantalla.idCatPantallas);

        obtNotasPantallaActual();

        obtProgresoPantalla(dataPantalla);
      } else {
        setIdPantallaActual(0);
      }
    }else{
      setAcceso(false);
    }
  };

  //Obtenemos las notas de la pantalla actual
  const obtNotasPantallaActual = async () => {
    let params = {
      idResPrecalifica: parseInt(idResPrecalifica),
      idCuestionario: parseInt(idCuestionarioSolicitud),
      //idPantallaActual:idPantallaActual
      idPantallaActual: 1,
    };

    let dataNotasPantalla = await post("getNotas", params);

    // Obtén el div con la identificación 'notas'
    const notasDiv = document.getElementById("notas");

    // Crea una instancia de createRoot
    const rootNotas = ReactDOM.createRoot(notasDiv);

    if (dataNotasPantalla.statuscode === 200) {
      // Renderizar el contenido de dataNotasPantalla en el componente de notas
      rootNotas.render(
        <div>
          {
            <NotasSolicitud
              numNota={dataNotasPantalla.infoNotas.idNotas}
              nota={dataNotasPantalla.infoNotas.nota1}
            />
          }
        </div>
      );
    } else {
      rootNotas.render(<SinNotas></SinNotas>);
    }
  };

  //Componente de progreso
  const obtProgresoPantalla = async (dataPantalla) => {
    if (
      dataPantalla.infoProcesoPantalla.idCatPantallas != null ||
      dataPantalla.infoProcesoPantalla.idCatPantallas !== ""
    ) {
      // let nombrePantalla = "1.Descargar solicitud";
      // var urlActual = window.location.pathname;
      // let credito = localStorage.getItem("idCredito");

      // let pantallaActualMenu = (
      //   <PasosAseguir
      //     nombrePantalla={nombrePantalla}
      //     urlActual={urlActual}
      //     credito={credito}
      //   />
      // );

      var urlActual = window.location.pathname;
      let credito = localStorage.getItem("idCredito");
      let numeroPaso = "1. ";
      let descripcionPaso = " Descargar solicitud";
      let nombrePantalla = ` ${descripcionPaso}`;
      let numeroPantalla = `${numeroPaso}`;

      let pantallaActualMenu = (
        <PasosAseguir
          numeroPantalla={
            <>
              <span className="numero-paso">{numeroPantalla}</span>
            </>
          }
          nombrePantalla={
            <span className="descripcion-paso">{nombrePantalla}</span>
          }
          urlActual={urlActual}
          credito={credito}
        />
      );

      const root1 = ReactDOM.createRoot(
        document.getElementById("MenuRenderizado")
      );
      root1.render(pantallaActualMenu);

      validarUltimoProgreso(dataPantalla);
    }
  };

  const buscarIdPantalla = (objeto, valor) => {
    // Función para buscar la clave (propiedad) por el valor dado

    for (var clave in objeto) {
      if (objeto.hasOwnProperty(clave) && objeto[clave] === valor) {
        return clave;
      }
    }
    return null; // Si el valor no se encuentra, puedes devolver un valor predeterminado o null
  };

  function eliminarDiagonalPrincipalEnURL(url) {
    // Convierte la URL en un arreglo de caracteres para que sea más fácil manipular
    var caracteres = url.split("");
    var longitud = url.length;

    // Recorre la URL y elimina los caracteres en la diagonal principal ("/")
    for (var i = 0; i < caracteres.length; i++) {
      if (caracteres[i] === "/") {
        caracteres[i] = ""; // Elimina el carácter de diagonal
      }
    }

    // Une los caracteres nuevamente en una cadena
    var nuevaURL = caracteres.join("");
    return nuevaURL;
  }

  //Para verificar que no entre a una pantalla que no le corresponde segun el tipo de credito
  const verificarUltimoProgreso = async (dataPantalla, credito) => {
    //Ultima pantalla
    let idPantallaActual = dataPantalla.infoProcesoPantalla.idCatPantallas;
    //URL actual
    let urlActual = eliminarDiagonalPrincipalEnURL(window.location.pathname);

    //No tiene credito
    if (credito === "0") {
      let pantallasNoCredito = {
        1: "descargarSolicitud",
        2: "solicitudAceptada",
        3: "documentosVivienda",
        4: "avaluo",
        5: "notariaAsignada",
        6: "formalizacionFirma",
        7: "felicidades",
      };
      //Si la url actual pertenece a una pantalla superior a la que tenemos detectada en bd entonces reedirigira
      //Hacia la ultima pantalla donde se quedo
      //de las urls sin credito
      //let buscarPantalla = buscarIdPantalla(pantallasNoCredito, urlActual);
      let idPantallaUrl = Object.keys(pantallasNoCredito).find(key => pantallasNoCredito[key]===urlActual);
      if (idPantallaUrl > idPantallaActual) {
        navigate("/" + pantallasNoCredito[idPantallaActual]);
      }
    }
    //Tiene credito
    else {
      let pantallasConCredito = {
        1: "descargarSolicitud",
        2: "solicitudAceptada",
        3: "documentosVivienda",
        4: "avaluo",
        5: "notariaAsignada",
        6: "institucionExterna",
        7: "formalizacionFirma",
        8: "felicidades",
      };
      //let buscarPantalla = buscarIdPantalla(pantallasConCredito, urlActual);
      let idPantallaUrl = Object.keys(pantallasConCredito).find(key => pantallasConCredito[key]===urlActual);
      if (idPantallaUrl > idPantallaActual) {
        navigate("/" + pantallasConCredito[idPantallaActual]);
      }
    }
  };

  //Para verificar que no entre a una pantalla que no le corresponde segun el tipo de credito
  const validarUltimoProgreso = (dataPantalla) => {
    //Ultima pantalla
    let idPantallaActual = dataPantalla.infoProcesoPantalla.idCatPantallas;
    //URL actual
    let urlActual = eliminarDiagonalPrincipalEnURL(window.location.pathname);

    //Si la url actual pertenece a una pantalla superior a la que tenemos detectada en bd entonces reedirigira
    //Hacia la ultima pantalla donde se quedo
    let idPantallaUrlActual = Object.keys(pantallasFlujoBanco).find(key => pantallasFlujoBanco[key].pantalla === urlActual);

    if (pantallasFlujoBanco[idPantallaUrlActual].paso > pantallasFlujoBanco[idPantallaActual].paso){
      navigate("/" + pantallasFlujoBanco[idPantallaActual].pantalla);
    }
  }

  //Obtenemos la información
  const obtInformacion = async () => {
    let params = {
      idCatDocumento: 1,
      idResPrecalifica: idResPrecalifica,
      idCuestionario: idCuestionarioSolicitud,
    };
    let dataPost = await post("getDocumento", params);
    if (dataPost.statuscode === 200) {
      setDocumentoSubido(true);
      setDescargarSolicitud(() => ({
        ...descargarSolicitud,
        ...dataPost.value,
      }));
    } else {
      setDescargarSolicitud(() => ({
        idDocsTramite: 0,
      }));
    }
  };

  //Para enviar el documento
  const handleDocumentoChange = async (event) => {
    const fileInput = event.target;
    const selectedFile = fileInput.files[0];

    if (selectedFile) {
      Swal.fire({
        title: "¿Estas seguro de subir tu archivo?",
        text: "Una vez subido, no se podrá editar",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, mandar",
      }).then( async (result) => {
        if (result.isConfirmed) {
          const peticionArchivoS3 = await post("S3/subirArchivoUrl", {
            nombreArchivo: selectedFile.name,
          });

          if (peticionArchivoS3?.statuscode === 200) {
            const peticionS3 = await putS3(peticionArchivoS3.respuesta.url, selectedFile);

            if(peticionS3?.ok){
              const peticionSubirDoc = await post("subirDocumento", {
                rutaArchivo: peticionArchivoS3.respuesta.rutaArchivo,
                idCatDocumento: 1,
                idCuestionario: idCuestionarioSolicitud,
                idResPrecalifica: idResPrecalifica,
              });

              if (peticionSubirDoc?.statuscode === 200){
                setDocumentoSubido(true);
                dispararCorreo();
              }else{
                Swal.fire({
                  title: "Error al subir archivo",
                  text: "Vuelve a intentarlo",
                  icon: "warning",
                  confirmButtonText: "Aceptar",
                });
              }
            }
          }
        }else{
          fileInput.value = "";
        }
      });
    }

  }

  const dispararCorreo = async () => {
    //Cliente
    let paramsEjecutivo = {
      email: localStorage.getItem("correoEjecutivo"),
      evento: 3,
    };

    let mandarCorreo = await post("enviarCorreo", paramsEjecutivo);
    if (mandarCorreo.statuscode === 200) console.log("Mande Correo");
  };

  function TarjetaSubir({ onDocumentoChange }) {

    return (
      <div className="cont_loop bg_descarga">
        <img src={vector} alt="vector"></img>
        <h3>Sube tu solicitud</h3>
        <p className="descripcion">
          Guarda tu solicitud firmada en físico, ya que deberás llevarla a la
          firma de escritura.
        </p>
        <input
          type="file"
          name="documento"
          id="documento"
          accept=".pdf"
          onChange={(event) => onDocumentoChange(event)}
          className="btn btn_morado"
        />
        <p className="descripcion">Firmada y en formato .PDF</p>
      </div>
    );
  }

  function TarjetaFinSubidaDocs() {
    return (
      <div className="cont_loop documentoSubido">
        <h3>Ya subiste tu solicitud</h3>
        <p className="descripcion">Espera a que validen tu solicitud.</p>
      </div>
    );
  }

  useEffect(() => {
    const cargarDatos = async () => {
      try {
        await obtEmpresaUrl();
        setIsLoading(false);
      } catch (error) {
        console.error("Error al cargar datos:", error);
        setIsLoading(false);
      }
    };

    if(idResPrecalifica !== undefined && idCuestionarioSolicitud !== undefined){
      setAcceso(true);
      cargarDatos();
      obtInformacion();
      obtPantallaActual();
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    }

  }, [idResPrecalifica, idCuestionarioSolicitud]);

  return (
    <>
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
          <div className="loading-text">Cargando...</div>
        </div>
      )}
      {acceso === true && (
        <div className=" cont_Info">
          <Link to="/home" className="link linkGrande back">
            {" "}
            {"<"} Regresar a mis solicitudes
          </Link>

          <div id="MenuRenderizado" className="MenuRenderizado"></div>
          {/* <PasosAseguir></PasosAseguir> */}
          <h1>Descarga y firma tu solicitud</h1>

          <p>
            Hemos pre-llenado la solicitud con base a los datos que nos
            proporcionaste, por favor descargala y completa la información
            faltante. No olvides firmarla y anexarla.
          </p>

          <h3 className="titt">Notas</h3>
          <hr />
          <div id="notas"></div>
          <br />

          <div className="cont_flex con_flex_wrap descarga responsivoColumn">
            <TarjetaDescarga
              idResPrecalifica={idResPrecalifica}
              idCuestionario={idCuestionarioSolicitud}
            ></TarjetaDescarga>

            {
              documentoSubido ? (
                <div
                  id="informacion"
                  name="informacion"
                  style={{display:"contents" }}
                >
                  <TarjetaFinSubidaDocs />
                </div>
              ) : (
                <div
                  id="sinInformacion"
                  name="sinInformacion"
                  style={{display: "contents"}}
                >
                  <TarjetaSubir
                    onDocumentoChange={handleDocumentoChange}
                  />
                </div>
              )
            }

          </div>
        </div>
      )}

      {acceso === false && (
        <div className=" cont_Info">
          <h1>Debes iniciar el proceso</h1>
          <p>
            Da click en el banco donde quieras iniciar el proceso en el menú del
            lado izquierdo.
          </p>
        </div>
      )}
    </>
  );
}
