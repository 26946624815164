export const ruleEmail = (email)=>{
    let regexEmail =  /^[^\s@]+@[^\s@]+\.[^\s@]+$/i
    return email.match(regexEmail) ? true : false
}

// export const ruleRFC = (rfc)=>{
//     let ruleRFC = "^[A-z,Ñ,ñ,&]{3,4}[0-9]{2}[0-1][0-9][0-3][0-9][A-z,0-9]?[A-z,0-9]?[0-9,A-z]?$";
//     return rfc.match(ruleRFC) ? true : false
// }

export const ruleRFC = (rfc)=>{
    rfc=rfc.toUpperCase();
    let ruleRFC = /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/;
    //let ruleRFC = /^([A-zÑñ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-z\d]{2})([A\d])$/;
    return rfc.match(ruleRFC) ? true : false
}

export const ruleCP = (cp)=>{
    cp = cp.toString()

    let ruleCP = /^([0-9]{5})$/
    return cp.match(ruleCP) ? true : false
}

export const ruleTelefono = (telefono)=>{
    //let ruleTelefono = /^[2|3|4|5|6|][0-9]{9}$/
    let ruleTelefono = /^[0-9]*$/
    return telefono.match(ruleTelefono) ? true : false
}

export const ruleContraseña = (contraseña)=>{
    //^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+={}[\]:;<>,.?/~`|-]).{8,50}$
    let ruleContraseña = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+={}[\]:;<>,.?/~`|-]).{8,50}$/;
    return contraseña.match(ruleContraseña) ? true : false
}

export const ruleNacimiento = (nacimiento)=>{
    //let ruleNacimiento =  /^\d{4}\/\d{2}\/\d{2}$/;
    let ruleNacimiento = /^(0[1-9]|1\d|2\d|3[01])\/(0[1-9]|1[0-2])\/(\d{4})$/;
    return nacimiento.match(ruleNacimiento) ? true : false
}

export const ruleNacimientoLog = (nacimiento)=>{
    let ruleNacimiento = /^(\d{4})\/(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])$/;
    return nacimiento.match(ruleNacimiento) ? true : false
}

export const ruleCURP = (curp)=>{
    curp=curp.toUpperCase();
    let ruleCurp = /^[A-Za-z]{4}\d{6}[HM]\w{6}[0-9A-Za-z]$/;
    return curp.match(ruleCurp) ? true : false
}

export const ruleNumeroSeguridad = (nss)=>{
    let ruleNumeroSeguridad = /^\d{11}$/;
    return nss.match(ruleNumeroSeguridad) ? true : false
}

export const ruleColorHexadecimal = (color) => {
    let ruleColorHexadecimal = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
    return color.match(ruleColorHexadecimal) ? true : false
}

