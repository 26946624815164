import "../App.css";
import "../sass/style.css";
import { useNavigate } from "react-router-dom";
import Frame_43 from "../images/Frame_43.png";
import { useState, useEffect } from "react";
import { ModalBuroDeCredito, ModalLoadingGeneral } from "../components/Modals";
import { InfoScore } from "../components/Cards";
import Swal from "sweetalert2";
import { put, get, post } from "../utils/http";
import { Cookies } from "react-cookie";
import { obtEmpresaUrl } from "../utils/global_functions";
import { useSetDataPublic } from "../providers/dataPublic/PublicProvider";
// import noValido from "../images/No valido.png";
// import pendiente from "../images/Pendiente.png";
// import valido from "../images/Valido.png";

// <-------------------------------------------------------------------------------------------------------------------------------->
// <---------------------------------------- Mensaje cuando el paso1(cotización) fue aprobado -------------------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
export function Aprobado1() {
  const [isOpenModalBuro, setIsOpenModalBuro] = useState(false);
  const [statuscode, setstatuscode] = useState();
  const [pintarScore, setPintarScore] = useState();

  let responseBtBuro;
  const verModalburo = async () => {
    responseBtBuro = await get(
      "obtDatosPaso2PorIdResPaso1",
      parseInt(localStorage.getItem("idResPaso1Buro"))
    );
    setstatuscode(responseBtBuro.statuscode);
    if (responseBtBuro.statuscode === 200) {
      let infoGetScore = [
        {
          pagoMensual: responseBtBuro.paso2.pagoMensual,
          capacidadPago: responseBtBuro.paso2.capacidadPago,
          deuda: responseBtBuro.paso2.deuda,
          endeudamiento: responseBtBuro.paso2.endeudamiento,
          comportamiento: responseBtBuro.paso2.comportamiento,
          mensualidadSinDeuda: responseBtBuro.paso2.mensualidadSinDeuda,
          montoSinDeuda: responseBtBuro.paso2.montoSinDeuda,
          mensualidadConDeuda: responseBtBuro.paso2.mensualidadConDeuda,
          montoConDeuda: responseBtBuro.paso2.montoConDeuda,
        },
      ];

      let cardScore = infoGetScore.map((infoScore) => (
        <InfoScore
          pagoMensual={infoScore.pagoMensual}
          capacidadPago={infoScore.capacidadPago}
          deuda={infoScore.deuda}
          endeudamiento={infoScore.endeudamiento}
          comportamiento={infoScore.comportamiento}
          mensualidadSinDeuda={infoScore.mensualidadSinDeuda}
          montoSinDeuda={infoScore.montoSinDeuda}
          mensualidadConDeuda={infoScore.mensualidadConDeuda}
          montoConDeuda={infoScore.montoConDeuda}
        />
      ));
      setPintarScore(cardScore);
    }
  };

  useEffect(() => {
    verModalburo();
  }, []);

  return (
    <>
      <div className="bg_morado2">
        <p className="aprobado">
          <i className="fa fa-circle-check"></i> Aprobado
        </p>{" "}
        <h3>¡Felicidades! Cuentas con capacidad de crédito</h3>
        {statuscode === 200 && (
          <button
            className="btn btn_bco marginEspacio"
            onClick={() => {
              setIsOpenModalBuro(true);
            }}
          >
            Consulta los detalles de tu buró
          </button>
        )}
        <ModalBuroDeCredito
          open={isOpenModalBuro}
          cardScoreModal={pintarScore}
          close={() => setIsOpenModalBuro(false)}
        ></ModalBuroDeCredito>
      </div>
    </>
  );
}

// <-------------------------------------------------------------------------------------------------------------------------------->
// <--------------------- Mensaje mostrado en cuestionario PreAutoriza cuando el paso1(cotización) fue aprobado -------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
export function AprobadoLogueado() {
  const [isOpenModalBuro, setIsOpenModalBuro] = useState(false);
  const [statuscode, setstatuscode] = useState();
  const [pintarScore, setPintarScore] = useState();

  let responseBtBuro;
  const verModalburo = async () => {
    responseBtBuro = await get(
      "obtDatosPaso2PorIdResPaso1",
      parseInt(localStorage.getItem("idResPaso1Cuestionario"))
    );
    setstatuscode(responseBtBuro.statuscode);
    if (responseBtBuro.statuscode === 200) {
      let infoGetScore = [
        {
          pagoMensual: responseBtBuro.paso2.pagoMensual,
          capacidadPago: responseBtBuro.paso2.capacidadPago,
          deuda: responseBtBuro.paso2.deuda,
          endeudamiento: responseBtBuro.paso2.endeudamiento,
          comportamiento: responseBtBuro.paso2.comportamiento,
          mensualidadSinDeuda: responseBtBuro.paso2.mensualidadSinDeuda,
          montoSinDeuda: responseBtBuro.paso2.montoSinDeuda,
          mensualidadConDeuda: responseBtBuro.paso2.mensualidadConDeuda,
          montoConDeuda: responseBtBuro.paso2.montoConDeuda,
        },
      ];

      let cardScore = infoGetScore.map((infoScore) => (
        <InfoScore
          pagoMensual={infoScore.pagoMensual}
          capacidadPago={infoScore.capacidadPago}
          deuda={infoScore.deuda}
          endeudamiento={infoScore.endeudamiento}
          comportamiento={infoScore.comportamiento}
          mensualidadSinDeuda={infoScore.mensualidadSinDeuda}
          montoSinDeuda={infoScore.montoSinDeuda}
          mensualidadConDeuda={infoScore.mensualidadConDeuda}
          montoConDeuda={infoScore.montoConDeuda}
        />
      ));
      setPintarScore(cardScore);
    }
  };

  useEffect(() => {
    verModalburo();
  }, []);
  return (
    <>
      <div className="anchoEvenly bg_morado2 ">
        <h3 className="textoCentrado">
          {" "}
          ¡Felicidades! Cuentas con capacidad de crédito
        </h3>
        <p className="aprobado">
          <i className="fa fa-circle-check"></i> Aprobado
        </p>
        <button
          className="btn_bco"
          onClick={() => {
            setIsOpenModalBuro(true);
          }}
        >
          Consulta los detalles de tu buró
        </button>
        <ModalBuroDeCredito
          open={isOpenModalBuro}
          cardScoreModal={pintarScore}
          close={() => setIsOpenModalBuro(false)}
        ></ModalBuroDeCredito>
      </div>
    </>
  );
}

// <-------------------------------------------------------------------------------------------------------------------------------->
// <-------------------------------------- Mensaje cuando el paso1(cotización) no fue aprobado ------------------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
export function NoAprobado1() {
  const [isOpenModalBuro, setIsOpenModalBuro] = useState(false);
  const [statuscode, setstatuscode] = useState();
  const [pintarScore, setPintarScore] = useState();

  let responseBtBuro;
  const verModalburo = async () => {
    responseBtBuro = await get(
      "obtDatosPaso2PorIdResPaso1",
      parseInt(localStorage.getItem("idResPaso1Buro"))
    );
    setstatuscode(responseBtBuro.statuscode);
    if (responseBtBuro.statuscode === 200) {
      let infoGetScore = [
        {
          pagoMensual: responseBtBuro.paso2.pagoMensual,
          capacidadPago: responseBtBuro.paso2.capacidadPago,
          deuda: responseBtBuro.paso2.deuda,
          endeudamiento: responseBtBuro.paso2.endeudamiento,
          comportamiento: responseBtBuro.paso2.comportamiento,
          mensualidadSinDeuda: responseBtBuro.paso2.mensualidadSinDeuda,
          montoSinDeuda: responseBtBuro.paso2.montoSinDeuda,
          mensualidadConDeuda: responseBtBuro.paso2.mensualidadConDeuda,
          montoConDeuda: responseBtBuro.paso2.montoConDeuda,
        },
      ];

      let cardScore = infoGetScore.map((infoScore) => (
        <InfoScore
          pagoMensual={infoScore.pagoMensual}
          capacidadPago={infoScore.capacidadPago}
          deuda={infoScore.deuda}
          endeudamiento={infoScore.endeudamiento}
          comportamiento={infoScore.comportamiento}
          mensualidadSinDeuda={infoScore.mensualidadSinDeuda}
          montoSinDeuda={infoScore.montoSinDeuda}
          mensualidadConDeuda={infoScore.mensualidadConDeuda}
          montoConDeuda={infoScore.montoConDeuda}
        />
      ));
      setPintarScore(cardScore);
    }
  };

  useEffect(() => {
    verModalburo();
  }, []);

  return (
    <>
      <div className="cont_flex">
        <div className="bg_gris2">
          <p className="alert">
            {" "}
            <i className="fa fa-circle-exclamation"></i>
          </p>
          <h4>
            Con los datos que nos muestras no eres sujeto de crédito, sin
            embargo te recomendamos
          </h4>
          <ul>
            <li>
              <i className="fa fa-circle-check"></i>Pagar algunas deudas y
              cancelar tarjetas de crédito que no utilices.
            </li>
            <li>
              <i className="fa fa-circle-check"></i>Sumar ingresos con alguién
              más, como tu pareja.
            </li>
            <li>
              <i className="fa fa-circle-check"></i>Poner recordatorios en tu
              agenda para pagar tus tarjetas siempre a tiempo.
            </li>
            <li>
              <i className="fa fa-circle-check"></i>Sin embargo, puedes
              registrarte para ver las opciones de crédito disponibles.{" "}
            </li>
            {statuscode === 200 && (
              <button
                className="btn btn_bco marginEspacio"
                onClick={() => {
                  setIsOpenModalBuro(true);
                }}
              >
                Consulta los detalles de tu buró
              </button>
            )}
            <ModalBuroDeCredito
              open={isOpenModalBuro}
              cardScoreModal={pintarScore}
              close={() => setIsOpenModalBuro(false)}
            ></ModalBuroDeCredito>
          </ul>
        </div>
      </div>
    </>
  );
}

// <-------------------------------------------------------------------------------------------------------------------------------->
// <-------------------------------------- Mensaje cuando el paso1(cotización) no fue aprobado ------------------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
export function NoAprobado1Logueado() {
  const [isOpenModalBuro, setIsOpenModalBuro] = useState(false);
  const [statuscode, setstatuscode] = useState();
  const [pintarScore, setPintarScore] = useState();

  let responseBtBuro;
  const verModalburo = async () => {
    responseBtBuro = await get(
      "obtDatosPaso2PorIdResPaso1",
      parseInt(localStorage.getItem("idResPaso1Buro"))
    );
    setstatuscode(responseBtBuro.statuscode);
    if (responseBtBuro.statuscode === 200) {
      let infoGetScore = [
        {
          pagoMensual: responseBtBuro.paso2.pagoMensual,
          capacidadPago: responseBtBuro.paso2.capacidadPago,
          deuda: responseBtBuro.paso2.deuda,
          endeudamiento: responseBtBuro.paso2.endeudamiento,
          comportamiento: responseBtBuro.paso2.comportamiento,
          mensualidadSinDeuda: responseBtBuro.paso2.mensualidadSinDeuda,
          montoSinDeuda: responseBtBuro.paso2.montoSinDeuda,
          mensualidadConDeuda: responseBtBuro.paso2.mensualidadConDeuda,
          montoConDeuda: responseBtBuro.paso2.montoConDeuda,
        },
      ];

      let cardScore = infoGetScore.map((infoScore) => (
        <InfoScore
          pagoMensual={infoScore.pagoMensual}
          capacidadPago={infoScore.capacidadPago}
          deuda={infoScore.deuda}
          endeudamiento={infoScore.endeudamiento}
          comportamiento={infoScore.comportamiento}
          mensualidadSinDeuda={infoScore.mensualidadSinDeuda}
          montoSinDeuda={infoScore.montoSinDeuda}
          mensualidadConDeuda={infoScore.mensualidadConDeuda}
          montoConDeuda={infoScore.montoConDeuda}
        />
      ));
      setPintarScore(cardScore);
    }
  };

  useEffect(() => {
    verModalburo();
  }, []);

  return (
    <>
      <div className="cont_flex">
        <div className="bg_gris2">
          <p className="alert">
            {" "}
            <i className="fa fa-circle-exclamation"></i>
          </p>
          <h4>
            Con los datos que nos muestras no eres sujeto de crédito, sin
            embargo te recomendamos
          </h4>
          <br />
          <ul>
            <li>
              <i className="fa fa-circle-check"></i>Pagar algunas deudas y
              cancelar tarjetas de crédito que no utilices.
            </li>
            <li>
              <i className="fa fa-circle-check"></i>Sumar ingresos con alguién
              más, como tu pareja.
            </li>
            <li>
              <i className="fa fa-circle-check"></i>Poner recordatorios en tu
              agenda para pagar tus tarjetas siempre a tiempo.
            </li>
            {statuscode === 200 && (
              <button
                className="btn btn_bco marginEspacio"
                onClick={() => {
                  setIsOpenModalBuro(true);
                }}
              >
                Consulta los detalles de tu buró
              </button>
            )}
            <ModalBuroDeCredito
              open={isOpenModalBuro}
              cardScoreModal={pintarScore}
              close={() => setIsOpenModalBuro(false)}
            ></ModalBuroDeCredito>
          </ul>
        </div>
      </div>
    </>
  );
}

// <-------------------------------------------------------------------------------------------------------------------------------->
// <-------------------------------------- Mensaje cuando no se obtiene respuesta del buro  ------------------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
export function NoBuro() {
  const [isOpenModalBuro, setIsOpenModalBuro] = useState(false);
  const [statuscode, setstatuscode] = useState();
  const [pintarScore, setPintarScore] = useState();

  let responseBtBuro;
  const verModalburo = async () => {
    responseBtBuro = await get(
      "obtDatosPaso2PorIdResPaso1",
      parseInt(localStorage.getItem("idResPaso1Buro"))
    );
    setstatuscode(responseBtBuro.statuscode);
    if (responseBtBuro.statuscode === 200) {
      let infoGetScore = [
        {
          pagoMensual: responseBtBuro.paso2.pagoMensual,
          capacidadPago: responseBtBuro.paso2.capacidadPago,
          deuda: responseBtBuro.paso2.deuda,
          endeudamiento: responseBtBuro.paso2.endeudamiento,
          comportamiento: responseBtBuro.paso2.comportamiento,
          mensualidadSinDeuda: responseBtBuro.paso2.mensualidadSinDeuda,
          montoSinDeuda: responseBtBuro.paso2.montoSinDeuda,
          mensualidadConDeuda: responseBtBuro.paso2.mensualidadConDeuda,
          montoConDeuda: responseBtBuro.paso2.montoConDeuda,
        },
      ];

      let cardScore = infoGetScore.map((infoScore) => (
        <InfoScore
          pagoMensual={infoScore.pagoMensual}
          capacidadPago={infoScore.capacidadPago}
          deuda={infoScore.deuda}
          endeudamiento={infoScore.endeudamiento}
          comportamiento={infoScore.comportamiento}
          mensualidadSinDeuda={infoScore.mensualidadSinDeuda}
          montoSinDeuda={infoScore.montoSinDeuda}
          mensualidadConDeuda={infoScore.mensualidadConDeuda}
          montoConDeuda={infoScore.montoConDeuda}
        />
      ));
      setPintarScore(cardScore);
    }
  };

  useEffect(() => {
    verModalburo();
  }, []);

  return (
    <>
      <div className="cont_flex">
        <div className="bg_gris2">
          <p className="alert"></p>
          <h1 className="titulo-sentimos">¡Lo sentimos!</h1>
          <h4>
            {" "}
            <i className="fa fa-circle-exclamation"></i> No nos fue posible
            consultar tu buró de crédito, sin embargo, registrate para ver las
            opciones de crédito disponibles
          </h4>
        </div>
      </div>
    </>
  );
}

// <-------------------------------------------------------------------------------------------------------------------------------->
// <-------------------------------------- Mensaje cuando no se obtiene respuesta del buro LOGUEADO  ------------------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
export function NoBuroLogueado() {
  const [isOpenModalBuro, setIsOpenModalBuro] = useState(false);
  const [statuscode, setstatuscode] = useState();
  const [pintarScore, setPintarScore] = useState();

  let responseBtBuro;
  const verModalburo = async () => {
    responseBtBuro = await get(
      "obtDatosPaso2PorIdResPaso1",
      parseInt(localStorage.getItem("idResPaso1Buro"))
    );
    setstatuscode(responseBtBuro.statuscode);
    if (responseBtBuro.statuscode === 200) {
      let infoGetScore = [
        {
          pagoMensual: responseBtBuro.paso2.pagoMensual,
          capacidadPago: responseBtBuro.paso2.capacidadPago,
          deuda: responseBtBuro.paso2.deuda,
          endeudamiento: responseBtBuro.paso2.endeudamiento,
          comportamiento: responseBtBuro.paso2.comportamiento,
          mensualidadSinDeuda: responseBtBuro.paso2.mensualidadSinDeuda,
          montoSinDeuda: responseBtBuro.paso2.montoSinDeuda,
          mensualidadConDeuda: responseBtBuro.paso2.mensualidadConDeuda,
          montoConDeuda: responseBtBuro.paso2.montoConDeuda,
        },
      ];

      let cardScore = infoGetScore.map((infoScore) => (
        <InfoScore
          pagoMensual={infoScore.pagoMensual}
          capacidadPago={infoScore.capacidadPago}
          deuda={infoScore.deuda}
          endeudamiento={infoScore.endeudamiento}
          comportamiento={infoScore.comportamiento}
          mensualidadSinDeuda={infoScore.mensualidadSinDeuda}
          montoSinDeuda={infoScore.montoSinDeuda}
          mensualidadConDeuda={infoScore.mensualidadConDeuda}
          montoConDeuda={infoScore.montoConDeuda}
        />
      ));
      setPintarScore(cardScore);
    }
  };

  useEffect(() => {
    verModalburo();
  }, []);

  return (
    <>
      <div className="cont_flex">
        <div className="bg_gris2">
          <p className="alert"></p>
          <h1 className="titulo-sentimos">¡Lo sentimos!</h1>
          <h4>
            {" "}
            <i className="fa fa-circle-exclamation"></i> No nos fue posible
            consultar tu buró de crédito, sin embargo, puedes continuar con el
            proceso para ver las opciones de crédito disponibles.{" "}
          </h4>
        </div>
      </div>
    </>
  );
}

// <-------------------------------------------------------------------------------------------------------------------------------->
// <--------------------------------------------- Mensaje cuando no tienes cotizaciones -------------------------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
export function SinCotizacion() {
  return (
    <>
      <h1 className="titt">Mis cotizaciones</h1>
      <p>
        Aquí podrás visualizar todas tus cotizaciones, obtener información
        detallada sobre los mejores créditos y comenzar tu solicitud.
      </p>
      <p className="aviso_sinDatos">
        <i className="fa fa-circle-exclamation"></i> Aún no tienes cotizaciones.
        Cotiza y obtén la oferta ideal.
      </p>

      <div className="banner">
        <h3>Buscamos la mejor opción para ti.</h3>
        <p>
          Cuéntanos qué buscas en un crédito y nosotros nos encargaremos de
          encontrar la mejor oferta para ti.
        </p>
        <a href="simulacion" className="btn btn_morado bg_alternativo">
          Cotiza ahora
        </a>
      </div>
    </>
  );
}

// <-------------------------------------------------------------------------------------------------------------------------------->
// <--------------------------------------- Mensaje renderizado cuando si tienes cotizaciones -------------------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
export function ConCotizacionP1() {
  return (
    <>
      <h1 className="titt">Mis cotizaciones</h1>
      <p>
        Aquí podrás ver todas tus cotizaciones, conocer a detalle los mejores
        créditos e iniciar tu solicitud.
      </p>
      <div className="Resum cont-Cotizaciones">
        <hr />
        <h3>
          <b>Estas son tus cotizaciones creadas</b>
        </h3>
        <p>Puedes generar todas las cotizaciones que necesites</p>
        <p>Por favor ingresa la información general si no lo ha hecho</p>
      </div>
    </>
  );
}

// <-------------------------------------------------------------------------------------------------------------------------------->
// <--------------------------------------- Mensaje renderizado si tiene bancos seleccionados -------------------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
export function ConBancosSeleccionadosP1() {
  return (
    <>
      <h1 className="titt">Mis solicitudes de crédito</h1>
      <p>
        Aquí podrás visualizar todas tus solicitudes y obtener información detallada sobre tus solicitudes
      </p>
      <div className="Resum cont-Cotizaciones">
        <hr />
        <h3>
          <b>Estas son tus solicitudes de crédito</b>
        </h3>
        <p>Puedes visualizar el estado de tus solicitudes de crédito de tus cotizaciones</p>
        <p>Por favor ingresa la información general si no lo ha hecho</p>
      </div>
    </>
  );
}

// <-------------------------------------------------------------------------------------------------------------------------------->
// <--------------------------------------- Mensaje renderizado para realizar una cotización --------------------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
export function ConCotizacionP2() {
  return (
    <>
      <div className="Resum cont-Cotizaciones">
        <div className="bg_morado2">
          <div>
            <h2>Buscamos la mejor opción para ti.</h2>
          </div>
          <div className="cont_btn_cotiza">
            <a href="simulacion" className="btn btn_personalizado bg_alternativo">
              Cotiza ahora
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

// <-------------------------------------------------------------------------------------------------------------------------------->
// <--------------------------------- Mensaje con botón para guardar las dos ofertas seleccionadas  -------------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
export function ConSeleccionBancos() {
  const [isOpenModalLoading, setIsOpenModalLoading] = useState(false);
  const setDataPublic = useSetDataPublic();
  const navigate = useNavigate();
  const cookies = new Cookies();

  function guardarBancos() {
    if (
      localStorage.getItem("BancoLS1") === null &&
      localStorage.getItem("BancoLS2") === null
    ) {
      Swal.fire({
        icon: "error",
        title: "Debe de seleccionar al menos una oferta para continuar",
        text: "Inténtelo de nuevo.",
        showCancelButton: false,
        confirmButtonText: "Aceptar",
      }).then((result) => {});
    } else {
      Swal.fire({
        title: "Espera...",
        showCancelButton: true,
        confirmButtonText: "Continuar",
        cancelButtonText: "Regresar",
        icon: "info",
        html:
          "<b>¡Importante! ¿Ya tienes una propiedad en mente?</b></br></br>" +
          "Si aún estás en la fase de búsqueda, te recomendamos seguir adelante con el proceso de autorización de tu crédito hipotecario. Esto te permitirá realizar una compra acorde a tus posibilidades financieras y firmar un contrato con total seguridad.",
      }).then((result) => {
        if (result.isConfirmed) {
          escogerBancosFunction();
        }
      });
    }
  }

  const escogerBancosFunction = async () => {
    setIsOpenModalLoading(true);
    let bancoSeleccionadoNo1 = parseInt(localStorage.getItem("BancoLS1"));
    let bancoSeleccionadoNo2 = parseInt(localStorage.getItem("BancoLS2"));

    let jsonBancosGuardar = {
      // idResPrecalifica1: bancoSeleccionadoNo1,
      // idResPrecalifica2: bancoSeleccionadoNo2,
    };

    if (!isNaN(bancoSeleccionadoNo1)) {
      jsonBancosGuardar.idResPrecalifica1 = bancoSeleccionadoNo1;
    } else {
      jsonBancosGuardar.idResPrecalifica1 = 0;
    }
    if (!isNaN(bancoSeleccionadoNo2)) {
      jsonBancosGuardar.idResPrecalifica2 = bancoSeleccionadoNo2;
    } else {
      jsonBancosGuardar.idResPrecalifica2 = 0;
    }

    //JPB En caso de que la opcion 1 sea 0, se va a llenar con opcion 2, y ahora opcion 2 pasa a ser 0
    if (jsonBancosGuardar.idResPrecalifica1 === 0) {
      jsonBancosGuardar.idResPrecalifica1 = bancoSeleccionadoNo2;
      jsonBancosGuardar.idResPrecalifica2 = 0;
    }

    let dataPostBancos = await put(
      "seleccionarBancosPreAutoriza",
      jsonBancosGuardar
    );

    if (dataPostBancos.statuscode === 200) {
      localStorage.removeItem("BancoLS1");
      localStorage.removeItem("BancoLS2");
      setIsOpenModalLoading(false);
      //KRP 17-11-2023 Para disparar el correo de seleccion de bancos al cliente
      await dispararCorreo();

      // Actualización del la barra lateral
      setDataPublic({actualizarNav: true});

      Swal.fire({
        icon: "success",
        title: "Gracias por confiar en nosotros",
        text: "Unos de nuestros ejecutivos se pondrá en contacto contigo para solicitar algunos documentos y continuar con el proceso.",

        showCancelButton: false,
        confirmButtonText: "Aceptar",
      }).then((result) => {
        navigate("/informacion-general");
      });
    } else {
      setIsOpenModalLoading(false);
      Swal.fire({
        icon: "error",
        title: "Algo salió mal",
        text: "Inténtelo nuevamente más tarde.",

        showCancelButton: false,
        confirmButtonText: "Aceptar",
      }).then((result) => {});
    }
  };

  const dispararCorreo = async () => {
    //Cliente
    let params = {
      email: cookies.get("correo"),
      evento: 16,
    };
    let mandarCorreo = await post("enviarEmail", params);
    if (mandarCorreo.statuscode === 200) console.log("");
  };

  useEffect(() => {
    obtEmpresaUrl();
  }, []);

  return (
    <>
      <div className="Resum cont-Cotizaciones">
        <div className="bg_morado2 padding_pequeno responsivoColumn">
          <h2>¿Quieres iniciar tu solicitud con las opciones seleccionadas?</h2>
          <button
            className="btn btn_personalizado bg_alternativo"
            id="btnAplicarBancosPequeno"
            onClick={guardarBancos}
          >
            Sí, iniciar
          </button>
          <button
            className="btn_morado_personalizado bg_alternativo"
            id="btnAplicarBancosGrande"
            onClick={guardarBancos}
          >
            Sí, iniciar
          </button>
        </div>
        <ModalLoadingGeneral open={isOpenModalLoading}></ModalLoadingGeneral>
      </div>
    </>
  );
}

// <-------------------------------------------------------------------------------------------------------------------------------->
// <--------------------------------------- Mensaje para ir a la pantalla "Mis cotizaciones"  -------------------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
export function ConCotizacionP13() {
  return (
    <>
      <h1 className="titt"> </h1>
      <div className="cont_responsive">
        <a href="/simulacion">
          <img src={Frame_43} alt="simulacion" />{" "}
        </a>
      </div>
      <div className="">
        <div className="header">
          <br></br>
          <div className="elementoFlex">
            <h3>Mis cotizaciones</h3>
            <a href="cotizaciones" className="link">
              Ir a mis cotizaciones
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export function NotasSolicitud({ numNota, nota }) {
  return (
    <>
      <div>
        <p>{nota}</p>
        <hr />
      </div>
    </>
  );
}

export function NotasSolicitudFelicidades() {
  return (
    <>
      <div>
        <p>
          Te recomendamos seguir estos{" "}
          <a
            className=""
            href="https://saturn5.mx/?_gl=1%2A1mu5bvz%2A_ga%2AMjEyNjY5NzUwOS4xNjkyMjA2MTg1%2A_ga_1XQFG88MC9%2AMTY5MzUxNTAwMi4yLjAuMTY5MzUxNTAwMi42MC4wLjA.#section-101-17"
            target="_blank" // Agregamos este atributo para abrir en una nueva pestaña
          >
            consejos
          </a>{" "}
          para tener una hipoteca feliz y seguir construyendo tu patrimonio.
        </p>
        <hr />
      </div>
    </>
  );
}

export function SinNotas() {
  return (
    <>
      <div>
        <p>Sin notas</p>
        <hr />
      </div>
    </>
  );
}

export function CertificadosNotaria({ idEstatus }) {
  let pendiente = "Pendiente.png";
  let Valido = "Valido.png";
  let Novalido = "No valido.png";

  return (
    <>
      <div className="Resum">
        <div className="header">
          <h3>Certificados</h3>
        </div>

        <div className="cont_flex con_flex_wrap certificados responsivoColumn">
          <div className="cont_loop">
            <h3>Agua</h3>
            {idEstatus[0] === 1 ? (
              <img
                className="imgBancos"
                alt="Banco"
                src={require(`../images/${pendiente}`)}
              />
            ) : idEstatus[0] === 2 ? (
              <img
                className="imgBancos"
                alt="Banco"
                src={require(`../images/${Valido}`)}
              />
            ) : (
              <img
                className="imgBancos"
                alt="Banco"
                src={require(`../images/${Novalido}`)}
              />
            )}
            <p>
              {idEstatus[0] === 1
                ? "Pendiente"
                : idEstatus[0] === 2
                ? "Valido"
                : idEstatus[0] === 3
                ? "No valido"
                : "Sin ningun estatus"}
            </p>
          </div>

          <div className="cont_loop">
            <h3>Predio</h3>
            {idEstatus[1] === 1 ? (
              <img
                className="imgBancos"
                alt="Banco"
                src={require(`../images/${pendiente}`)}
              />
            ) : idEstatus[1] === 2 ? (
              <img
                className="imgBancos"
                alt="Banco"
                src={require(`../images/${Valido}`)}
              />
            ) : (
              <img
                className="imgBancos"
                alt="Banco"
                src={require(`../images/${Novalido}`)}
              />
            )}
            <p>
              {idEstatus[1] === 1
                ? "Pendiente"
                : idEstatus[1] === 2
                ? "Valido"
                : idEstatus[1] === 3
                ? "No valido"
                : "Sin ningun estatus"}
            </p>
          </div>

          <div className="cont_loop">
            <h3>Libre gravemen</h3>
            {idEstatus[2] === 1 ? (
              <img
                className="imgBancos"
                alt="Banco"
                src={require(`../images/${pendiente}`)}
              />
            ) : idEstatus[2] === 2 ? (
              <img
                className="imgBancos"
                alt="Banco"
                src={require(`../images/${Valido}`)}
              />
            ) : (
              <img
                className="imgBancos"
                alt="Banco"
                src={require(`../images/${Novalido}`)}
              />
            )}
            <p>
              {idEstatus[2] === 1
                ? "Pendiente"
                : idEstatus[2] === 2
                ? "Valido"
                : idEstatus[2] === 3
                ? "No valido"
                : "Sin ningun estatus"}
            </p>
          </div>

          <div className="cont_loop">
            <h3>Constancia de zonificación</h3>
            {idEstatus[3] === 1 ? (
              <img
                className="imgBancos"
                alt="Banco"
                src={require(`../images/${pendiente}`)}
              />
            ) : idEstatus[3] === 2 ? (
              <img
                className="imgBancos"
                alt="Banco"
                src={require(`../images/${Valido}`)}
              />
            ) : (
              <img
                className="imgBancos"
                alt="Banco"
                src={require(`../images/${Novalido}`)}
              />
            )}
            <p>
              {idEstatus[3] === 1
                ? "Pendiente"
                : idEstatus[3] === 2
                ? "Valido"
                : idEstatus[3] === 3
                ? "No valido"
                : "Sin ningun estatus"}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export function MsjCantidadClientesRef() {
  const cookies = new Cookies();
  const [cantidadClientes, setCantidadClientes] = useState(0);

  const obtCantidadReferenciados = async () => {
    try {
      const data = await get(
        "getCantidadClientesReferenciados",
        cookies.get("idSocio")
      );
      if (data.statuscode === 200) {
        setCantidadClientes(data.cantidadReferenciados);
      } else {
        //console.error("Error en la consulta: ", data && data.message);
      }
    } catch (error) {
      console.error("Error al obtener clientes: ", error);
    }
  };
  useEffect(() => {
    obtCantidadReferenciados();
  }, []);
  return (
    <>
      <div id="msjClientesRef">
        <p>Clientes referenciados:</p>{" "}
        <b className="cantidadClientesClass">{cantidadClientes}</b>
      </div>
    </>
  );
}

export function MsjCantidadComisiones() {
  const cookies = new Cookies();
  const [cantidadComisiones, setCantidadComisiones] = useState(0);

  const obtCantidadComisiones = async () => {
    try {
      const data = await get(
        "getCantidadClientesReferenciados",
        cookies.get("idSocio")
      );
      if (data.statuscode === 200) {
        setCantidadComisiones(data.cantidadComisiones);
      } else {
        //console.error("Error en la consulta: ", data && data.message);
      }
    } catch (error) {
      console.error("Error al obtener clientes: ", error);
    }
  };
  useEffect(() => {
    obtCantidadComisiones();
  }, []);
  return (
    <>
      <div id="msjCreditosOtorgados">
        <p>Cr&eacute;ditos otorgados:</p>{" "}
        <b className="cantidadClientesClass">{cantidadComisiones}</b>
      </div>
    </>
  );
}

export function MsjTotalComision() {
  const cookies = new Cookies();
  const [totalComision, setTotalComision] = useState(0);

  const obtTotalComision = async () => {
    try {
      const data = await get(
        "getCantidadClientesReferenciados",
        cookies.get("idSocio")
      );
      if (data.statuscode === 200) {
        setTotalComision(data.sumatoriaComision);
      } else {
        //console.error("Error en la consulta: ", data && data.message);
      }
    } catch (error) {
      console.error("Error al obtener clientes: ", error);
    }
  };

  function formatCurrency(number) {
    return number.toLocaleString("es-CO", {
      style: "currency",
      currency: "COP",
    });
  }

  useEffect(() => {
    obtTotalComision();
  }, []);
  return (
    <>
      <div id="msjCreditosOtorgados">
        <p>Monto ganado total:</p>{" "}
        <b className="cantidadClientesClass">{formatCurrency(totalComision)}</b>
      </div>
    </>
  );
}
// <-------------------------------------------------------------------------------------------------------------------------------->
// <-------------------------------------- Mensaje cuando no tienes cotizaciones ni solicitudes------------------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
export function SinCotizacionYSolicitud() {
  return (
    <div>
      <h2 className="titt">Mis cotizaciones de cr&eacute;dito</h2>
      <p>
        Aquí podrás visualizar todas tus cotizaciones, obtener información
        detallada sobre los mejores créditos y comenzar tu solicitud.
      </p>
      <p className="aviso_sinDatos">
        <i className="fa fa-circle-exclamation"></i> Aún no tienes cotizaciones.
        Cotiza y obtén la oferta ideal.
      </p>

      <h2 className="titt">Mis solicitudes cr&eacute;dito</h2>
      <p>Aquí podrás visualizar todas tus solicitudes.</p>
      <p className="aviso_sinDatos">
        <i className="fa fa-circle-exclamation"></i> Aún no tienes solicitudes.
        Cotiza y obtén la oferta ideal.
      </p>

      <div className="banner">
        <h3>Buscamos la mejor opción para ti.</h3>
        <p>
          Cuéntanos qué buscas en un crédito y nosotros nos encargaremos de
          encontrar la mejor oferta para ti.
        </p>
        <a href="simulacion" className="btn btn_morado bg_alternativo">
          Cotiza ahora
        </a>
      </div>
    </div>
  );
}
// <-------------------------------------------------------------------------------------------------------------------------------->
// <--------------------------------------------- Mensaje cuando no tienes cotizaciones -------------------------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
export function SinSolicitudes() {
  return (
    <>
      <div>
        <h3 className="titt">Mis solicitudes de cr&eacute;dito</h3>
        <p>
          Aquí podrás visualizar todas tus solicitudes y obtener información
          detallada sobre tus solicitudes.
        </p>
        <p className="aviso_sinDatos">
          <i className="fa fa-circle-exclamation"></i> Aún no tienes
          solicitudes. Cotiza y obtén la oferta ideal.
        </p>
      </div>
    </>
  );
}
