import "../../../App.css";
import { useState, useEffect } from "react";
import { Cookies } from "react-cookie";
import { get, post } from "../../../utils/http";
import { TarjetaDocumentosViviendaVendedor } from "../../../components/Cards";
import { obtEmpresaUrl } from "../../../utils/global_functions";
import Swal from "sweetalert2";
import { ModalTokenNoValido } from "../../../components/Modals";


export default function DocumentosViviendaVendedor() {
  const cookies = new Cookies();

  const [claseVendedorVivienda, setClaseVendedorVivienda]  = useState("cont_loop");
  const [claseDocumentacionVivienda, setClaseDocumentacionVivienda]  = useState("cont_loop");

  const [isLoading, setIsLoading] = useState(true);
  const [habilitarEnvioInfo, setHabilitarEnvioInfo] = useState(false);

  function ocultarHeaderFooter() {
    const headerElement = document.querySelector("header");
    if (headerElement) {
      headerElement.style.display = "none";
    }

    const headerResponsiveElement = document.querySelector("div.header_responsive");
    if (headerResponsiveElement) {
      headerResponsiveElement.style.display = "none";
    }

    const footerElement = document.querySelector("footer");
    if (footerElement) {
      footerElement.style.display = "none";
    }
  }
  
  const enviar = async () => {
    if (habilitarEnvioInfo && !cookies.get("tokenExp")){
      let params2 = {
        idResPrecalifica: cookies.get("idResPre"),
        idCuestionario: cookies.get("idCuestionario"),
        idUser: cookies.get("idUsuario")
      };
      let enviarInformacion = await post("enviarInformacionCompletaViviendaOnbase", params2);
  
      if(enviarInformacion.statuscode === 200){
        Swal.fire({
          icon: "success",
          title: "Información enviada",
          //text: "Intente enviar la información más tarde",
          confirmButtonText: "Aceptar"
        });
        console.log("Información enviada");
        //dispararCorreo();
        //window.location.href = "/informacionEnviada";
      }else{
        Swal.fire({
          icon: "error",
          title: "Ocurrió un error",
          text: "Intente enviar la información más tarde",
          confirmButtonText: "Aceptar"
        });
        console.error("Error al enviar información");
      }
    }else{
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Verifique que haya completado toda la información solicitada",
        confirmButtonText: "Aceptar"
      });
    }
  };

  const dispararCorreo = async () => {
    //Ejecutivo
    let paramsEjecutivo = {
      email: localStorage.getItem("correoEjecutivo"),
      evento: 32,
    };
    console.log(paramsEjecutivo);
    let mandarCorreo = await post("enviarCorreo", paramsEjecutivo);
    if (mandarCorreo.statuscode === 200) console.log("");
  };

  //JPB Verifica Las secciones subidas
  const evaluarInfoSecciones = async () => {
    let documentacionVivienda = await get(
      "getVerificarDocumentacionVivienda",
      cookies.get("idResPre")
    );
    let vendedorVivienda = await get(
      "getVerificarVendedorVivienda",
      cookies.get("idResPre")
    );

    if(documentacionVivienda.completo === true)
      setClaseDocumentacionVivienda("cont_loop documentoSubido");

    if(vendedorVivienda.completo === true)
      setClaseVendedorVivienda("cont_loop documentoSubido");

    if (documentacionVivienda.statuscode === 200 && documentacionVivienda.completo === true &&
      vendedorVivienda.statuscode === 200 && vendedorVivienda.completo === true) {
      setHabilitarEnvioInfo(true);  
    }
  }

  const actualizarToken = async () =>{
    const res = await post("actualizarTokenInvitacionVendedor", {
      token: cookies.get("token"),
      idUsuario: cookies.get("idUsuario"),
      idResPrecalifica: cookies.get("idResPre"),
      idCuestionario: cookies.get("idCuestionario"),
    });

    if (res.statuscode === 200){
      Swal.fire({
        title: "Enlace actualizado",
        text: "Revise su correo",
        icon: "success",
        confirmButtonText: "Aceptar",
      });
    }else{
      Swal.fire({
        title: "Error al actualizar enlace",
        text: "Intente más tarde",
        icon: "error",
        confirmButtonText: "Aceptar",
      });
    }
  }

  useEffect(() => {
    const cargarDatos = async () => {
      try {
        ocultarHeaderFooter();
        await obtEmpresaUrl();
        await evaluarInfoSecciones();
        setIsLoading(false);
      } catch (error) {
        console.error("Error al cargar datos:", error);
        setIsLoading(false);
      }
    };

    cargarDatos();

  }, []);

  return (
    <>
      {isLoading ? (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
          <div className="loading-text">Cargando...</div>
        </div>
      ) : (
        <div className="">

          {
            cookies.get("tokenExp") && (
              <ModalTokenNoValido actualizarToken={actualizarToken}/>
            )
          }

          <div id="MenuRenderizado" className="MenuRenderizado"></div>

          <h1>Documentos de vivienda</h1>

          <p>
            Hemos notificado al banco tu decisi&oacute;n de continuar y ahora
            necesitamos que nos compartas los siguientes documentos de la vivienda
            que deseas adquirir.
          </p>

          <div className="cont_Info docVivienda">
            <div className="cont_flex con_flex_wrap flex_center responsivoColumn">
              <TarjetaDocumentosViviendaVendedor
                clase={claseVendedorVivienda}
                nombre={"Información y documentación del vendedor"}
                enlace={"documentosViviendaVendedor/infoVendedorVivienda"}
              />
              <TarjetaDocumentosViviendaVendedor
                clase={claseDocumentacionVivienda}
                nombre={"Documentacion de la vivienda"}
                enlace={"/documentosViviendaVendedor/documentacionVivienda"}
              />
            </div>

            <hr />
            <br />

            <button className="btn btn_bco"
              onClick={enviar}
            >
              Enviar
            </button>
            
          </div>
        </div>
      )
      }
      
    </>
  );
}
