import Cookies from "universal-cookie";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { ruleEmail } from "../../../assets/validateRules";
import { post } from "../../../utils/http";
import "../../../App.css";
import { obtEmpresaUrl } from "../../../utils/global_functions";
import imgInicio from "../../../images/CrearCuenta.jpg";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import { jwtDecode } from "jwt-decode";

export function RaizEjecutivo({ children, verificar }) {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const cargarDatos = async () => {
      try {
        await obtEmpresaUrl();
        setIsLoading(false);
      } catch (error) {
        console.error("Error al cargar datos:", error);
        setIsLoading(false);
      }
    };

    cargarDatos();
  }, []);

  return (
    <>
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
          <div className="loading-text">Cargando...</div>
        </div>
      )}
      <Header verificar={verificar} />
      {children}
      <Footer />
    </>
  );
}

export function InicioEjecutivo() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const cookies = new Cookies();
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  // Función para manejar el envío del formulario
  const onSubmit = () => {
    let timerInterval;
    Swal.fire({
      title: "Iniciando sesión",
      timer: 20000,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
        const b = Swal.getHtmlContainer().querySelector("b");
        timerInterval = setInterval(() => {
          b.textContent = Swal.getTimerLeft();
        }, 100);
      },
      willClose: () => {
        clearInterval(timerInterval);
      },
    }).then((result) => {
      if (result.dismiss === Swal.DismissReason.timer) {
        Swal.fire({
          title: "Upss...",
          confirmButtonText: "Aceptar",
          icon: "info",
          html: "<b>El tiempo de espera ha sido superado, intentalo mas tarde...</b>",
        });
      }
    });
    signIn();
  };

  // Función para realizar la autenticación
  const signIn = async () => {
    let data = {
      email: email,
      password: password,
    };

    let peticion = await post("loginEjecutivo", data);

    if (peticion?.statuscode === 200) {

      cookies.remove("sessionEjecutivo", { path: "/" });
      cookies.remove("nombreEjecutivo", { path: "/" });
      cookies.remove("idEjecutivo", { path: "/" });
      cookies.remove("rol", { path: "/" });
      cookies.remove("token", { path: "/" });

      const data = jwtDecode(peticion?.respuesta);

      switch(data?.rol){
        case "ejecutivo":
          setCookies(peticion?.respuesta, data);
          window.location.href = "/ejecutivo/clientes";
          break;
        case "administrador_empresa":
          setCookies(peticion?.respuesta, data);
          window.location.href = "/administradorEmpresa/invitaciones";
          break;
        case "administrador_saturn":
          setCookies(peticion?.respuesta, data);
          window.location.href = "/administradorSaturn/roles";
          break;
        default:
          Swal.fire({
            title: "Error",
            text: "Acceso no autorizado!",
            icon: "error",
            confirmButtonText: "Aceptar",
          });
          break;
      }
      
    } else {
      Swal.fire({
        title: "Usuario y/o contraseña erronea!",
        text: "Verifica que la cuenta y la contraseña sea correcta",
        icon: "error",
        confirmButtonText: "Aceptar",
      });
    }
  };

  function setCookies(token, data){
    cookies.set("sessionEjecutivo", true, { path: "/" });
    cookies.set("nombreEjecutivo", data?.nombre, { path: "/" });
    cookies.set("idEjecutivo", data?.idEjecutivo, { path: "/" });
    cookies.set("rol", data?.rol, { path: "/" });
    cookies.set("token", token, { path: "/" });
  }

  // Función para mostrar/ocultar la contraseña
  function verPassword() {
    var x = document.getElementById("password");
    var icono = document.getElementById("iconoPassword");
    if (x.type === "password") {
      x.type = "text";
      icono.className = "fa fa-eye-slash iconoOcultarPassword";
    } else {
      x.type = "password";
      icono.className = "fa fa-eye iconoOcultarPassword";
    }
  }

  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    obtEmpresaUrl();
  }, []);

  return (
    <>
      <div className="cont_flex">
        <div className="col6">
          <h1>
            Acceso a ejecutivos{" "}
          </h1>
          <p>Accede para ver el estatus de tus clientes.</p>
          <form onSubmit={handleSubmit(onSubmit)}>
            <label htmlFor="email">Correo electrónico </label>
            <input
              type="email"
              name="email"
              id="email"
              autoComplete="off"
              value={email}
              onInput={(ev) => setEmail(ev.target.value)}
              {...register("email", { required: true, validate: ruleEmail })}
            />
            {errors.email?.type === "required" && (
              <span className="span">Este campo es requerido</span>
            )}
            {errors.email && (
              <span className="span"> El formato del correo no es valido</span>
            )}
            <label htmlFor="password">Contraseña </label>
            <div className="relativePosition">
              <input
                type="password"
                name="password"
                id="password"
                value={password}
                {...register("password", { required: true })}
                onInput={(ev) => setPassword(ev.target.value)}
              />
              <i
                id="iconoPassword"
                className="fa fa-eye iconoOcultarPassword"
                onClick={() => {
                  verPassword();
                }}
              ></i>
            </div>
            {errors.password?.type === "required" && (
              <span className="span">Este campo es requerido</span>
            )}
            <div className="botones">
              <button type="submit" className="btn btn_morado" id="btnIniciar">
                Iniciar sesión
              </button>
            </div>
            <Link to="/recuperarPassEjecutivo" className="link">
              Olvidé mi contraseña
            </Link>
          </form>
        </div>

        <div className="col6 cont_img-index">
          <img src={imgInicio} alt="Inicio Ejecutivo" />
        </div>
      </div>
    </>
  );
}
