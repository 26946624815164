import "../../../App.css";
import "../../../sass/style.css";
import FooterHome from "../../../components/FooterHome";
import { NavAdminEmpresa } from "../../../components/NavAdminEmpresa";
import HeaderAdminEmpresa from "../../../components/HeaderAdminEmpresa";


export default function TemplateAdminEmpresa({children,active}){
  return(
    <>
      <HeaderAdminEmpresa/>
        <div className="cont_flex">
          <NavAdminEmpresa active={active}/>
          {children}
        </div> 
      <FooterHome/>
    </>
  )
}