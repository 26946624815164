import React, { useState, useEffect } from "react";
import { obtEmpresaUrl } from "../../../utils/global_functions.js";
import { get, post, putS3 } from "../../../utils/http.js";
import { useForm } from "react-hook-form";
import { TablaEmpresas } from "../../../components/Tablas.jsx";
import Swal from "sweetalert2";
import { ruleColorHexadecimal } from "../../../assets/validateRules.js";

export default function EmpresasAdminSaturn() {
  const [isLoading, setIsLoading] = useState(true);
  const [empresas, setEmpresas] = useState([]);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
    }
  });

  useEffect(() => {
    const cargarDatos = async () => {
      try {
        await obtEmpresaUrl();
        //await renderDropdowns();
        cargarEmpresas();
        setIsLoading(false);
      } catch (error) {
        console.error("Error al cargar datos:", error);
      }
    };

    cargarDatos();
  }, []);

  async function cargarEmpresas(){
    const peticion = await get("adminSaturn/obtEmpresas", "");
    if(peticion.statuscode === 200){
      setEmpresas(peticion.respuesta);
    }
  }

  const crearEmpresa = async (data) => {
    let rutaLogoFooter = null;
    if(data.logoPie[0]){
      const peticionLogoFooterS3 = await post("S3/subirArchivoUrl", {
        nombreArchivo: data.logoPie[0].name,
      });

      if (peticionLogoFooterS3?.statuscode === 200) {
        const peticionS3 = await putS3(peticionLogoFooterS3.respuesta.url, data.logoPie[0]);
  
        if(peticionS3?.ok){
          rutaLogoFooter = peticionLogoFooterS3.respuesta.rutaArchivo;
        }
      }
    }

    const peticionArchivoS3 = await post("S3/subirArchivoUrl", {
      nombreArchivo: data.logo[0].name,
    });

    if (peticionArchivoS3?.statuscode === 200) {
      const peticionS3 = await putS3(peticionArchivoS3.respuesta.url, data.logo[0]);

      if(peticionS3?.ok){
        const peticionCreacionEmpresa = await post("adminSaturn/crearEmpresa", {
          nombreEmpresa: data.nombre,
          rutaLogo: peticionArchivoS3.respuesta.rutaArchivo,
          rutaLogoFooter: rutaLogoFooter,
          colorPrimario: data.colorPrimario,
          colorSecundario: data.colorSecundario,
          fuentePrimaria: data.fuentePrimaria,
          fuenteSecundaria: data.fuenteSecundaria
        });

        if(peticionCreacionEmpresa?.statuscode === 200){
          Swal.fire({
            icon: "success",
            title: "Empresa creada correctamente",
            confirmButtonText: "Aceptar"
          });
          reset({ nombre: "", logo: "", logoFooter: "", colorPrimario: "", colorSecundario: "", fuentePrimaria: "", fuenteSecundaria: ""});
          cargarEmpresas();
        }else{
          Swal.fire({
            icon: "error",
            title: "Algo salió mal",
            text: "Inténtelo nuevamente más tarde.",
            confirmButtonText: "Aceptar"
          });
        }
      }
    }
  }

  return (
    <>
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
          <div className="loading-text">Cargando...</div>
        </div>
      )}

      <div className="cont_Info">
        <h1 className="titt">Empresas</h1>
        <p className="descripcionClientes">
          Visualiza y crea empresas
        </p>

        <div className="cont_flex cont-form">
          <form onSubmit={handleSubmit(crearEmpresa)}>
            
            <div className="col3 campo-form">
              <label>Nombre</label>
              <input
                type="text"
                maxLength={50}
                autoComplete="off"
                {...register("nombre", { required: true })}
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^A-Za-z0-9ÁÉÍÓÚáéíóúÜüÑñ ]/g, "");
                }}
              />
              {errors.nombre?.type === "required" && (
                <span className="span">Este campo es requerido</span>
              )}
            </div>
            <div className="col3 campo-form">
              <label>Logo</label>
              <input
                type="file"
                accept="image/*"
                multiple={false}
                {...register("logo", { required: true })}
              />
              {errors.logo?.type === "required" && (
                <span className="span">Este campo es requerido</span>
              )}
            </div>
            <div className="col3 campo-form">
              <label>Logo pie de página</label>
              <input
                type="file"
                accept="image/*"
                multiple={false}
                {...register("logoPie")}
              />
            </div>

            <div className="col3 campo-form">
              <label>Color primario</label>
              <input
                type="text"
                maxLength={7}
                autoCapitalize="on"
                placeholder="#000000"
                autoComplete="off"
                {...register("colorPrimario", { required: true, validate: ruleColorHexadecimal })}
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9A-Fa-f#]/g, "");
                }}
              />
              {errors.colorPrimario?.type === "required" ? (
                <span className="span">Este campo es requerido</span>
              ) : (
                errors.colorPrimario?.type === "validate" && (
                  <span className="span">Formato no válido</span>
                )
              )}
            </div>
            <div className="col3 campo-form">
              <label>Color secundario</label>
              <input
                type="text"
                maxLength={7}
                placeholder="#000000"
                autoComplete="off"
                {...register("colorSecundario", { required: true, validate: ruleColorHexadecimal })}
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9A-Fa-f#]/g, "");
                }}
              />
              {errors.colorSecundario?.type === "required" ? (
                <span className="span">Este campo es requerido</span>
              ) : (
                errors.colorSecundario?.type === "validate" && (
                  <span className="span">Formato no válido</span>
                )
              )}
            </div>

            <div className="col3 campo-form">
              <label>Fuente primaria</label>
              <input
                type="text"
                maxLength={50}
                autoComplete="off"
                {...register("fuentePrimaria", { required: true })}
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^A-Za-z\-, ]/g, "");
                }}
              />
              {errors.fuentePrimaria?.type === "required" && (
                <span className="span">Este campo es requerido</span>
              )}
            </div>
            <div className="col3 campo-form">
              <label>Fuente secundaria</label>
              <input
                type="text"
                maxLength={50}
                autoComplete="off"
                {...register("fuenteSecundaria", { required: true })}
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^A-Za-z\-, ]/g, "");
                }}
              />
              {errors.fuenteSecundaria?.type === "required" && (
                <span className="span">Este campo es requerido</span>
              )}
            </div>
            
            <br/> <br/>
            <div className="">
              <button
                type="submit"
                className="btn btn_morado"
              >
                Crear
              </button>
            </div>
          </form>
        </div>

        <br/>
        <br/>

        <div className="tabla_clientes">
          <TablaEmpresas data={empresas}/>
        </div>
        <br/>

      </div>
    </>
  );
}