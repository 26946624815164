import "../../App.css";

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Cookies } from "react-cookie";
import Swal from "sweetalert2";
import { set, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import * as ReactDOM from "react-dom/client";
import {
  TarjetaSubirDocumentacion,
  PasosAseguir,
} from "../../components/Cards";
import { NotasSolicitud, SinNotas } from "../../components/Msjs";
import { get, post, put, getCP, postDocs, putS3 } from "../../utils/http";
import { ruleEmail } from "../../assets/validateRules";
import { obtEmpresaUrl } from "../../utils/global_functions";

export default function InfoVendedorVivienda() {
  const cookies = new Cookies();
  const {
    register,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const navigate = useNavigate();
  const [validarLongitudCP, setValidarLongitudCP] = useState();

  //Datos del vendedor
  const [tipoPersona, setTipoPersona] = useState("");
  const [tipoInmueble, setTipoInmueble] = useState("");
  const [usoInmueble, setUsoInmueble] = useState("");

  //Fisica
  const [email, setEmail] = useState("");
  const [nombre, setNombre] = useState("");
  const [aPaterno, setAPaterno] = useState("");
  const [aMaterno, setAMaterno] = useState("");
  const [telCel, setTelCel] = useState("");
  const [validarLongitudTelefono, setValidarLongitudTelefono] = useState();
  const [invalidEmail, setInvalidEmail] = useState(false);

  //Moral
  const [razonSocial, setRazonSocial] = useState("");
  const [nombrecontactoEmpresa, setNombrecontactoEmpresa] = useState("");
  const [telContacto, setTelContacto] = useState("");
  const [emailContacto, setEmailContacto] = useState("");
  const [rfc, setRfc] = useState("");
  const [validarLongitudTelefonoMoral, setValidarLongitudTelefonoMoral] =
    useState();
  const [invalidEmailMoral, setInvalidEmailMoral] = useState(false);

  //Direccion
  const [cp, setCp] = useState("");
  const [calle, setCalle] = useState("");
  const [numExt, setNumExt] = useState("");
  const [numInt, setNumInt] = useState("");
  const [delegacion, setDelegacion] = useState("");
  const [estado, setEstado] = useState("");
  const [colonia, setColonia] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  //Para controlar logica de direcciones o validaciones
  const [error, setError] = useState(null);
  const [estadoDisabled, setEstadoDisabled] = useState(true);
  const [estadoAux, setEstadoAux] = useState("");
  const [delegacionDisabled, setDelegacionDisabled] = useState(true);
  const [listColonias, setListColonias] = useState();

  const [tel, setTel] = useState("");
  const [infoVendedorVivienda, setInfoVendedorVivienda] = useState({});
  let params;
  const [documentacionVivienda, setDocumentacionVivienda] = useState({
    documento: null, // Estado para almacenar el archivo seleccionado
  });

  const [recargarDocumentos, setRecargarDocumentos] = useState(false);

  // Función para obtener datos de código postal.
  const obtenerDataCp = async (cp) => {
    try {
      let respuestaPorCP = await getCP("info_cp", cp);
      // console.log(respuestaPorCP);
      setError(false);
      obtenerClaveIso(respuestaPorCP.response.estado);
      setEstadoAux(respuestaPorCP.response.estado);
      setDelegacion(respuestaPorCP.response.municipio);
      listColoniasCP(respuestaPorCP.response.asentamiento);
      setEstadoDisabled(true);
      setDelegacionDisabled(true);
    } catch (error) {
      // Habilitar los inputs en caso de error
      setEstadoDisabled(false);
      setDelegacionDisabled(false);
      setColonia(""); // Esto borrará el contenido del campo "colonia"
      setEstadoAux("");
      setDelegacion("");
      setError(true);
    }
  };

  // Función para listar colonias.
  const listColoniasCP = (colonias) => {
    let opcionDefault = (
      <option value="" disabled>
        Seleccione una opción...{" "}
      </option>
    );

    colonias = colonias.map((colonia) => (
      <option value={colonia} key={colonia}>
        {colonia}
      </option>
    ));
    colonias.unshift(opcionDefault);

    setListColonias(colonias);
  };

  // Función para obtener la clave ISO del estado.
  const obtenerClaveIso = async (estadoCP) => {
    let obtenerClave = await get("obtClaveISOPorEstado", estadoCP);
    setEstadoAux(estadoCP);
    if (obtenerClave.statuscode === 200) {
      setEstado(obtenerClave.iso);
    }
  };

  let idResPrecalifica = localStorage.getItem("idResPrecalifica");
  let idCuestionarioSolicitud = localStorage.getItem("idCuestionarioSolicitud");
  // Definimos un estado para idPantallaActual
  const [idPantallaActual, setIdPantallaActual] = useState(null);

  //Obtenemos la pantalla actual 20-09-23
  const obtPantallaActual = async () => {
    let dataPantalla = await get("getPantallaActual", idResPrecalifica);
    if (dataPantalla.statuscode === 200) {
      if (
        dataPantalla.infoProcesoPantalla.idCatPantallas !== null ||
        dataPantalla.infoProcesoPantalla.idCatPantallas !== ""
      ) {
        setIdPantallaActual(dataPantalla.infoProcesoPantalla.idCatPantallas);

        obtNotasPantallaActual();

        obtProgresoPantalla(dataPantalla);
      } else {
        setIdPantallaActual(0);
      }
    }
  };

  //Obtenemos las notas de la pantalla actual 20-09-23
  const obtNotasPantallaActual = async () => {
    params = {
      idResPrecalifica: parseInt(idResPrecalifica),
      idCuestionario: parseInt(idCuestionarioSolicitud),
      idPantallaActual: 8, //Asignamos directo ya que no esta respetando la función asincrona por lo que al usar idPantallaActual no lo identifica
    };

    let dataNotasPantalla = await post("getNotas", params);

    // Obtén el div con la identificación 'notas'
    const notasDiv = document.getElementById("notas");

    // Crea una instancia de createRoot
    const rootNotas = ReactDOM.createRoot(notasDiv);

    if (dataNotasPantalla.statuscode === 200) {
      // Renderizar el contenido de dataNotasPantalla en el componente de notas
      rootNotas.render(
        <div>
          {
            <NotasSolicitud
              numNota={dataNotasPantalla.infoNotas.idNotas}
              nota={dataNotasPantalla.infoNotas.nota1}
            />
          }
        </div>
      );
    } else {
      rootNotas.render(<SinNotas></SinNotas>);
    }
  };

  //Componente de progreso
  const obtProgresoPantalla = async (dataPantalla) => {
    if (
      dataPantalla.infoProcesoPantalla.idCatPantallas != null ||
      dataPantalla.infoProcesoPantalla.idCatPantallas !== ""
    ) {
      // let nombrePantalla = "3.Vivienda";
      // var urlActual = "/documentosVivienda";
      // let credito = localStorage.getItem("idCredito");

      // let pantallaActualMenu = (
      //   <PasosAseguir
      //     nombrePantalla={nombrePantalla}
      //     urlActual={urlActual}
      //     credito={credito}
      //   />
      // );
      var urlActual = "/documentosVivienda";
      let credito = localStorage.getItem("idCredito");
      let numeroPaso = "3. ";
      let descripcionPaso = "Vivienda";
      let nombrePantalla = ` ${descripcionPaso}`;
      let numeroPantalla = `${numeroPaso}`;

      let pantallaActualMenu = (
        <PasosAseguir
          numeroPantalla={
            <>
              <span className="numero-paso">{numeroPantalla}</span>
            </>
          }
          nombrePantalla={
            <span className="descripcion-paso">{nombrePantalla}</span>
          }
          urlActual={urlActual}
          credito={credito}
        />
      );

      const root1 = ReactDOM.createRoot(
        document.getElementById("MenuRenderizado")
      );
      root1.render(pantallaActualMenu);
    }
  };

  //Obtenemos información general
  const obtInformacion = async () => {
    //Parametro fijo hasta que determinemos como se obtiene
    //let data = await get('getInfoVendedorVivienda', 294);
    let data = await get("getInfoVendedorVivienda", idResPrecalifica);

    // console.log(data);
    if (data.statuscode === 200) {
      setTipoPersona(data.infoVendedorVivienda[0].tipoVendedor.toString());
      setValue(
        "tipoPersona",
        data.infoVendedorVivienda[0].tipoVendedor.toString()
      );
      setNombrecontactoEmpresa(
        data.infoVendedorVivienda[0].nombrecontactoEmpresa
      );
      setEmail(data.infoVendedorVivienda[0].email);
      setEmailContacto(data.infoVendedorVivienda[0].email);
      setNombre(data.infoVendedorVivienda[0].nombre);
      setAPaterno(data.infoVendedorVivienda[0].aPaterno);
      setAMaterno(data.infoVendedorVivienda[0].aMaterno);
      setCalle(data.infoVendedorVivienda[0].calle);
      setNumExt(data.infoVendedorVivienda[0].numExt);
      setNumInt(data.infoVendedorVivienda[0].numInt);
      setCp(data.infoVendedorVivienda[0].cp);
      setDelegacion(data.infoVendedorVivienda[0].delegacion);
      setEstado(data.infoVendedorVivienda[0].estado);
      setError(true);
      setColonia(data.infoVendedorVivienda[0].colonia);
      setRfc(data.infoVendedorVivienda[0].rfc);
      setTelCel(data.infoVendedorVivienda[0].telCel);
      setRazonSocial(data.infoVendedorVivienda[0].razonSocial);
      setTelContacto(data.infoVendedorVivienda[0].telContacto);

      setTipoInmueble(data.infoVendedorVivienda[0].tipoInmueble);
      setUsoInmueble(data.infoVendedorVivienda[0].usoInmueble);

      obtDocumentos(data.infoVendedorVivienda[0].tipoVendedor);
      setEstadoAux(data.infoVendedorVivienda[0].delegacion);
    }
  };

  //Función especifica para enviar información
  const sendInfoVendedor = async () => {
    params = {
      idResPrecalifica: parseInt(idResPrecalifica),
      idCuestionario: parseInt(idCuestionarioSolicitud),
      tipoVendedor: parseInt(tipoPersona),
      email: parseInt(tipoPersona) == 1 ? emailContacto : email,
      nombre: nombre,
      aPaterno: aPaterno,
      aMaterno: aMaterno,
      calle: calle,
      numExt: numExt,
      numInt: numInt,
      cp: cp !== "" ? cp : 0,
      //delegacion: delegacion,
      delegacion: estadoAux,
      estado: estado,
      colonia: colonia,
      rfc: rfc,
      telCel: telCel,
      razonSocial: razonSocial,
      nombrecontactoEmpresa: nombrecontactoEmpresa,
      telContacto: telContacto,
      tipoInmueble: parseInt(tipoInmueble),
      usoInmueble: usoInmueble !== "" ? parseInt(usoInmueble) : 0,
    };
    //console.log(params);
  };

  //Funcion general para guardar la información
  const guardarInformacion = async (mostrarDialogo = true) => {
    await sendInfoVendedor();
    // Verificamos si tiene información
    let getInfo = await get("getInfoVendedorVivienda", idResPrecalifica);
    console.log(getInfo);

    // Lógica de Swal aquí
    const mostrarSwal = (title, icon) => {
      if (mostrarDialogo) {
        Swal.fire({
          title: title,
          icon: icon,
          confirmButtonText: "Aceptar",
        });
      }
    };

    if (getInfo.statuscode === 404) {
      let insert = await post("addInfoVendedorVivienda", params);

      //let params2 = {
      //  idResPrecalifica: parseInt(idResPrecalifica),
      //  idCuestionario: parseInt(idCuestionarioSolicitud),
      //  idUser: parseInt(cookies.get("id"))
      //};
      //let enviarInformacion = await post("enviarInformacionCompletaViviendaOnbase", params2);

      if (insert.statuscode === 200) {
        //if(enviarInformacion.statuscode === 200){
          mostrarSwal("Datos guardados correctamente", "success");
          navigate("/documentosVivienda");
        //}
        
      } else {
        mostrarSwal("Datos ingresados erroneos", "error");
      }
    } else {
      if (getInfo.statuscode === 200) {
        //let params2 = {
        //  idResPrecalifica: parseInt(idResPrecalifica),
        //  idCuestionario: parseInt(idCuestionarioSolicitud),
        //  idUser: parseInt(cookies.get("id"))
        //};
        //let enviarInformacion = await post("enviarInformacionCompletaViviendaOnbase", params2);
        let update = await put("updateInfoVendedorVivienda", params);
        if (update.statuscode === 200) {
          //if(enviarInformacion.statuscode === 200){
          mostrarSwal("Datos actualizados correctamente", "success");
          navigate("/documentosVivienda");
          //}
        } else {
          mostrarSwal("Datos ingresados erroneos", "error");
        }
      }
    }
  };

  //Para tomar los documentos
  const handleDocumentoChange = async (event, errorCB = () => {}) => {
    const selectedFile = event.target.files[0];
    const inputName = event.target.name;
    //console.log("Archivo seleccionado:", selectedFile);

    if (inputName == "16" || inputName == "13" || inputName == "8") {
      const selectedFiles = event.target.files;
      if (selectedFiles.length > 0) {
        try {
          
          let insertDocumento;
          for (let i = 0; i < selectedFiles.length; i++) {
            const peticionArchivoS3 = await post("S3/subirArchivoUrl", {
              nombreArchivo: selectedFiles[i].name,
            });

            if (peticionArchivoS3?.statuscode === 200) {
              const peticionS3 = await putS3(peticionArchivoS3.respuesta.url, selectedFiles[i]);
              if(peticionS3?.ok){
                insertDocumento = await post("subirDocumento", {
                  rutaArchivo: peticionArchivoS3.respuesta.rutaArchivo,
                  idCatDocumento: inputName,
                  idCuestionario: idCuestionarioSolicitud,
                  idResPrecalifica: idResPrecalifica,
                });
              }
            }
          }
          if (insertDocumento.statuscode === 200) {
            //Recargamos solo los documentos en lugar de toda la pagina
            setRecargarDocumentos(true);
          } else {
            errorCB();
            Swal.fire({
              title: "Datos ingresados erróneos",
              text: "Verifica que tus datos sean correctos ",
              icon: "error",
              confirmButtonText: "Aceptar",
            });
          }
        } catch (error) {
          // Maneja errores aquí
        }
      } else {
        // console.log("No se seleccionó ningún archivo");
      }
    }else{
      if (selectedFile) {
        setDocumentacionVivienda((prevInfo) => ({
          ...prevInfo,
          documento: selectedFile,
        }));
        try {
          const peticionArchivoS3 = await post("S3/subirArchivoUrl", {
            nombreArchivo: selectedFile.name,
          });

          if (peticionArchivoS3?.statuscode === 200) {
            const peticionS3 = await putS3(peticionArchivoS3.respuesta.url, selectedFile);

            if(peticionS3?.ok){
              const insertDocumento = await post("subirDocumento", {
                rutaArchivo: peticionArchivoS3.respuesta.rutaArchivo,
                idCatDocumento: inputName,
                idCuestionario: idCuestionarioSolicitud,
                idResPrecalifica: idResPrecalifica,
              });

              if (insertDocumento?.statuscode === 200) {
                setRecargarDocumentos(true);
              } else {
                errorCB();
                Swal.fire({
                  title: "Datos ingresados erroneos",
                  text: "Verifica que tus datos sean correctos ",
                  icon: "error",
                  confirmButtonText: "Aceptar",
                });
              }
            }
          }
        } catch (error) {
          // Maneja errores aquí
        }
      } else {
        // console.log("No se seleccionó ningún archivo");
      }
    }
  };

  //Para verificar si ya tiene un documento
  const verificarDocumento = async (idCatDocumento) => {
    let params = {
      idResPrecalifica: parseInt(idResPrecalifica),
      idCuestionario: parseInt(idCuestionarioSolicitud),
      idCatDocumento: idCatDocumento,
    };

    let verificarDocumento;
    if (idCatDocumento == "16" || idCatDocumento == "13" || idCatDocumento == "8"){
      verificarDocumento = await post("getInfoDocumentos", params);
    }else{
      verificarDocumento = await post("getInfoDocumento", params);
    }

    if (verificarDocumento.statuscode === 200) {
      if (idCatDocumento == "16" || idCatDocumento == "13" || idCatDocumento == "8"){

        const docs = verificarDocumento.value.map((doc) => {
          return{
            ...doc,
            archivo: doc.archivo.split("/").pop()
          }
        })
        //console.log(docs)
        return {
          documentos: docs,
          info: verificarDocumento.info,
        };
      }else{
        let cadena = verificarDocumento.value.archivo;
        // Dividir la cadena por las barras invertidas para obtener partes
        const partes = cadena.split("/");

        // Obtener la última parte que contiene el nombre de archivo y extensión
        const nombreArchivoConExtension = partes[partes.length - 1];

        return {
          nombreDoc: nombreArchivoConExtension,
          info: verificarDocumento.info,
        };
      }
    } else {
      return verificarDocumento.info;
    }
  };

  useEffect(()=>{
    if (recargarDocumentos){
      //Recargamos solo los documentos en lugar de toda la pagina
      const idPerdona = document.getElementById("tipoPersona").value;
      obtDocumentos(idPerdona);
      setRecargarDocumentos(false);
    }
  }, [recargarDocumentos]);

  //Para obtener todos los documentos
  var docs;
  const obtDocumentos = async (tipo) => {
    // console.log(tipo);
    if (tipo != 0 && tipo != "") {
      let data = await get("getDocumentosByPantalla", "3");
      if (data.statuscode === 200) {
        let documentos = [];
        //Tipo persona 1
        let arrDocsCatTipo1 = data.documentos[tipo].documentos;
        //console.log(arrDocsCatTipo1);
        // Crear un array de promesas para verificar los documentos
        const verificarPromises = arrDocsCatTipo1.map(async (documento) => {
          try {
            let resVerDoc = await verificarDocumento(documento.idCatDocumentos);
            if(documento.idCatDocumentos  == "16" || documento.idCatDocumentos == "13" || documento.idCatDocumentos == "8"){
              return {
                titulo: documento.nombre,
                tipoDoc: documento.idCatDocumentos,
                docVerificado: resVerDoc.info, // Guardar el resultado de la verificación
                documentos: resVerDoc.documentos
              };
            }else{
              return {
                titulo: documento.nombre,
                parrafo:
                  documento.idCatDocumentos === "3" ||
                  documento.idCatDocumentos === "4"
                    ? "Nota de recibo"
                    : "",
                link: documento.idCatDocumentos !== "2" ? false : true,
                enlace:
                  documento.idCatDocumentos !== "2"
                    ? ""
                    : "https://saturn5.mx/contrato-ejemplo.docx",
                tipoDoc: documento.idCatDocumentos,
                docVerificado: resVerDoc.info, // Guardar el resultado de la verificación
                nombreDocumento: resVerDoc.nombreDoc,
              };
            }
          } catch (error) {
            console.error("Error al verificar el documento", error);
            return null;
          }
        });

        // Esperar a que todas las promesas se completen
        const documentosWithVerificaciones = await Promise.all(
          verificarPromises
        );

        // Filtrar los resultados nulos en caso de errores
        const documentosValidos = documentosWithVerificaciones.filter(
          (doc) => doc !== null
        );

        if (documentosWithVerificaciones.length === 0) {
          // console.log("No hay documentos");
        } else {
          //console.log(documentosWithVerificaciones);
          docs = documentosWithVerificaciones.map((documento) => {
            return (
              <TarjetaSubirDocumentacion
                titulo={documento.titulo}
                parrafo={documento.parrafo}
                link={documento.link}
                enlace={documento.enlace}
                onDocumentoChange={handleDocumentoChange}
                tipoDoc={documento.tipoDoc}
                del={true}
                documentoSubido={documento.docVerificado}
                notas={
                  documento.titulo === "Acta constitutiva de la empresa"
                    ? "Asegura que sea vigente y esta sea legible."
                    : documento.titulo ===
                      "Copia de poderes del representante legal"
                    ? "Anexa los poderes de la persona que firmara la escritura, debe de ser legible y estar vigente."
                    : documento.titulo === "Identificación oficial del apoderado"
                    ? "Anexa INE o Pasaporte vigente y asegura que sea legible."
                    : documento.titulo ===
                      "Estado de cuenta bancario de la empresa"
                    ? "Asegura que sean vigentes y sean legibles."
                    : documento.titulo === "Estado de cuenta"
                    ? "Asegura que sean vigentes y sean legibles."
                    : documento.titulo === "Acta de matrimonio"
                    ? "Asegura que sean vigentes y sean legibles."
                    : documento.titulo === "Identificación vendedor"
                    ? "Anexa INE o Pasaporte vigente y asegura que sea legible."
                    : documento.titulo ===
                      "Estado de cuenta bancario de la empresa"
                    ? "Asegura que sean vigentes y sean legibles."
                    : documento.titulo === "Otros documentos"
                    ? "Asegura que sean legibles y vigentes."
                    : "nota de reserva"
                }
                nombreDocumento={documento.nombreDocumento}
                documentos={documento.documentos}
              />
            );
          });

          const root = ReactDOM.createRoot(
            document.getElementById("documentosRenderPF")
          );

          root.render(docs);
        }
      }
    }
  };

  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    obtInformacion();
    obtPantallaActual();
    const cargarDatos = async () => {
      try {
        await obtEmpresaUrl();
        setIsLoading(false);
      } catch (error) {
        console.error("Error al cargar datos:", error);
        setIsLoading(false);
      }
    };
    cargarDatos();
  }, []);

  //TODO: falta los documentos deben mostrarse en verde, borrar y subirse bien.

  return (
    <>
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
          <div className="loading-text">Cargando...</div>
        </div>
      )}
      <div className=" cont_Info">
        <Link to="/documentosVivienda" className="link linkGrande back">
          {" "}
          {"<"} Regresar a información vivienda
        </Link>

        <div id="MenuRenderizado" className="MenuRenderizado"></div>

        <h1>Información vendedor y vivienda</h1>
        <p>
          Asegurate de poner la información correcta. Podrás regresar a
          editarlo.
        </p>

        <hr />

        <div className="Resum">
          <div className="header">
            <h3>Datos del vendedor</h3>
          </div>
          <div className="cont_flex cont-form">
            <form onSubmit={handleSubmit(() => guardarInformacion(true))}>
              {/*Tipo vendedor */}
              <div className="col3 campo-form estado marginEspacio">
                <label>Tipo de vendedor </label>
                <select
                  {...register("tipoPersona", { required: true })}
                  id="tipoPersona"
                  name="tipoPersona"
                  value={tipoPersona}
                  onChange={(ev) => {
                    setTipoPersona(ev.target.value);
                    obtDocumentos(ev.target.value);
                  }}
                >
                  <option value="">Selecciona</option>
                  <option value="1">Moral</option>
                  <option value="2">Fisica</option>
                </select>
                {errors.tipoPersona?.type === "required" && (
                  <span className="span">Este campo es requerido</span>
                )}
              </div>

              {/*Tipo de inmueble*/}
              <div className="col3 campo-form estado marginEspacio">
                <label>Tipo de inmueble </label>
                <select
                  value={tipoInmueble}
                  name="tipoInmueble"
                  onChange={(ev) => {
                    setTipoInmueble(ev.target.value);
                  }}
                >
                  <option value="0">Selecciona</option>
                  <option value="1">Casa Nueva</option>
                  <option value="2">Casa Usada</option>
                  <option value="3">Departamento Nuevo</option>
                  <option value="4">Departamento Usado</option>
                  <option value="5">Terreno</option>
                  <option value="6">Otro</option>
                </select>
                {errors.tipoInmueble?.type === "required" && (
                  <span className="span">Este campo es requerido</span>
                )}
              </div>

              {/*Uso que le dara al inmueble */}
              <div className="col3 campo-form estado marginEspacio">
                <label>Uso que le dara al inmueble</label>
                <select
                  value={usoInmueble}
                  name="usoInmueble"
                  onChange={(ev) => {
                    setUsoInmueble(ev.target.value);
                  }}
                >
                  <option value="0">Selecciona</option>
                  <option value="1">Principal</option>
                  <option value="2">Inversion/Descanso</option>
                </select>
                {errors.usoInmueble?.type === "required" && (
                  <span className="span">Este campo es requerido</span>
                )}
              </div>

              {tipoPersona === "2" && (
                <div id="personaFisica" name="personaFisica">
                  {/* Email */}
                  <div className="col3 campo-form">
                    <label>Email</label>
                    <input
                      type="email"
                      value={email}
                      name="emailFisica"
                      disabled={tipoPersona === "" || tipoPersona === "0"}
                      autoComplete="off"
                      onInput={(ev) => {
                        setEmail(ev.target.value);
                        const isValidEmail = ruleEmail(ev.target.value);
                        if (!isValidEmail) {
                          setInvalidEmail(true);
                        } else {
                          setInvalidEmail(false);
                        }
                      }}
                    />
                    {errors.emailFisica?.type === "required" && (
                      <span className="span">Este campo es requerido</span>
                    )}
                    {invalidEmail && (
                      <span className="span">
                        El formato del correo no es válido
                      </span>
                    )}
                  </div>

                  {/* Nombre */}
                  <div className="col3 campo-form">
                    <label>Nombre </label>

                    <input
                      type="text"
                      value={nombre}
                      name="nombre"
                      disabled={tipoPersona === "" || tipoPersona === "0"}
                      onInput={(ev) => {
                        setNombre(ev.target.value);
                      }}
                    />
                    {errors.nombre?.type === "required" && (
                      <span className="span">Este campo es requerido</span>
                    )}
                  </div>

                  {/* Apellido Paterno */}
                  <div className="col3 campo-form">
                    <label>Apellido Paterno</label>
                    <input
                      type="text"
                      value={aPaterno}
                      name="aPaterno"
                      disabled={tipoPersona === "" || tipoPersona === "0"}
                      onInput={(ev) => {
                        setAPaterno(ev.target.value);
                      }}
                    />
                    {errors.aPaterno?.type === "required" && (
                      <span className="span">Este campo es requerido</span>
                    )}
                  </div>

                  {/* Apellido Materno */}
                  <div className="col3 campo-form">
                    <label>Apellido Materno</label>
                    <input
                      type="text"
                      value={aMaterno}
                      name="aMaterno"
                      disabled={tipoPersona === "" || tipoPersona === "0"}
                      onInput={(ev) => {
                        setAMaterno(ev.target.value);
                      }}
                    />
                    {errors.aMaterno?.type === "required" && (
                      <span className="span">Este campo es requerido</span>
                    )}
                  </div>

                  {/* Telefono celular */}
                  <div className="col3 campo-form">
                    <label>Telefono celular</label>
                    <input
                      type="tel"
                      value={telCel}
                      name="telCel"
                      size="10"
                      maxLength={10}
                      placeholder="Teléfono a 10 dígitos"
                      autoComplete="off"
                      disabled={tipoPersona === "" || tipoPersona === "0"}
                      onInput={(ev) => {
                        // Filtrar solo dígitos
                        const tempTelefono = ev.target.value.replace(/\D/g, "");

                        if (tempTelefono.length <= 10) {
                          setTelCel(tempTelefono);
                          setValidarLongitudTelefono(
                            tempTelefono.length === 10
                          );
                        }
                      }}
                    />
                    {errors.telCel?.type === "required" && (
                      <span className="span">Este campo es requerido</span>
                    )}
                    {validarLongitudTelefono === false && (
                      <span className="span">Ingresar 10 digitos</span>
                    )}
                  </div>
                </div>
              )}

              {tipoPersona === "1" && (
                <div id="personaMoral" name="personaMoral">
                  {/* Razón social  */}
                  <div className="col3 campo-form">
                    <label>Razón social </label>
                    <input
                      type="text"
                      value={razonSocial}
                      name="razonSocial"
                      disabled={tipoPersona === "" || tipoPersona === "0"}
                      onInput={(ev) => {
                        setRazonSocial(ev.target.value);
                      }}
                    />
                    {errors.razonSocial?.type === "required" && (
                      <span className="span">Este campo es requerido</span>
                    )}
                  </div>

                  {/* Nombre contacto de la empresa */}
                  <div className="col3 campo-form">
                    <label>Nombre contacto de la empresa </label>
                    <input
                      type="text"
                      value={nombrecontactoEmpresa}
                      name="nombrecontactoEmpresa"
                      disabled={tipoPersona === "" || tipoPersona === "0"}
                      onInput={(ev) => {
                        setNombrecontactoEmpresa(ev.target.value);
                      }}
                    />
                    {errors.nombrecontactoEmpresa?.type === "required" && (
                      <span className="span">Este campo es requerido</span>
                    )}
                  </div>

                  {/* Telefono del contacto */}
                  <div className="col3 campo-form">
                    <label>Telefono del contacto</label>
                    <input
                      type="tel"
                      value={telContacto}
                      name="telContacto"
                      size="10"
                      maxLength={10}
                      placeholder="Teléfono a 10 dígitos"
                      disabled={tipoPersona === "" || tipoPersona === "0"}
                      onInput={(ev) => {
                        let tempTelefono = ev.target.value;
                        if (
                          !isNaN(tempTelefono) &&
                          tempTelefono.length === 10
                        ) {
                          setTelContacto(tempTelefono);
                          setValidarLongitudTelefonoMoral(false);
                        } else {
                          setTelContacto(tempTelefono);
                          setValidarLongitudTelefonoMoral(true);
                        }
                      }}
                    />
                    {errors.telContacto?.type === "required" && (
                      <span className="span">Este campo es requerido</span>
                    )}
                    {validarLongitudTelefonoMoral && (
                      <span className="span">Ingresar 10 dígitos</span>
                    )}
                  </div>

                  {/* Email del contactoo */}
                  <div className="col3 campo-form">
                    <label>Email del contacto</label>
                    <input
                      type="email"
                      value={emailContacto}
                      name="email"
                      disabled={tipoPersona === "" || tipoPersona === "0"}
                      autoComplete="off"
                      onInput={(ev) => {
                        setEmailContacto(ev.target.value);
                        const isValidEmail = ruleEmail(ev.target.value);
                        if (!isValidEmail) {
                          setInvalidEmailMoral(true);
                        } else {
                          setInvalidEmailMoral(false);
                        }
                      }}
                    />
                    {errors.email?.type === "required" && (
                      <span className="span">Este campo es requerido</span>
                    )}
                    {invalidEmailMoral && (
                      <span className="span">
                        El formato del correo no es válido
                      </span>
                    )}
                  </div>

                  {/* RFC */}
                  <div className="col3 campo-form">
                    <label>RFC</label>
                    <input
                      type="text"
                      value={rfc}
                      name="rfc"
                      disabled={tipoPersona === "" || tipoPersona === "0"}
                      onInput={(ev) => {
                        setRfc(ev.target.value);
                      }}
                    />
                    {errors.rfc?.type === "required" && (
                      <span className="span">Este campo es requerido</span>
                    )}
                  </div>
                </div>
              )}

              {tipoPersona === "" && (
                <div id="mensaje">
                  <p>Primero debe seleccionar el tipo de vendedor</p>
                </div>
              )}

              <div className="Resum">
                <div className="header">
                  <h3>Dirección de inmueble</h3>
                </div>
                <div className="cont_flex cont-form">
                  {/*Codigo postal */}
                  <div className="col3 campo-form">
                    <label>C&oacute;digo postal </label>
                    <input
                      type="tel"
                      value={cp}
                      id="cp"
                      name="cp"
                      size={5}
                      maxLength="5"
                      autoComplete="off"
                      disabled={tipoPersona === "" || tipoPersona === "0"}
                      onInput={(ev) => {
                        let tempCP = ev.target.value;
                        if (isNaN(tempCP) === false) {
                          if (tempCP.length < 5) {
                            setValidarLongitudCP(false);
                            setCp(tempCP);
                          } else {
                            if (tempCP.length === 5) {
                              obtenerDataCp(ev.target.value);
                              setCp(tempCP);
                              setValidarLongitudCP(true);
                            }
                          }
                        }
                      }}
                    />
                    <small>
                      Al ingresar su código postal correcto, en automático se
                      registrará su estado y municipio.
                    </small>
                    {errors.cp?.type === "required" && (
                      <span className="span">Este campo es requerido</span>
                    )}
                  </div>

                  {/*Calle */}
                  <div className="col3 campo-form">
                    <label>Calle </label>
                    <input
                      type="text"
                      value={calle}
                      name="calle"
                      disabled={tipoPersona === "" || tipoPersona === "0"}
                      onInput={(ev) => {
                        setCalle(ev.target.value);
                      }}
                    />
                    {errors.calle?.type === "required" && (
                      <span className="span">Este campo es requerido</span>
                    )}
                  </div>

                  {/*Num. Ext */}
                  <div className="col3 campo-form">
                    <label>Num. Ext </label>
                    <input
                      type="number"
                      value={numExt}
                      name="numExt"
                      disabled={tipoPersona === "" || tipoPersona === "0"}
                      onInput={(ev) => {
                        setNumExt(ev.target.value);
                      }}
                    />
                    {errors.numExt?.type === "required" && (
                      <span className="span">Este campo es requerido</span>
                    )}
                  </div>

                  {/*Num. Int */}
                  <div className="col3 campo-form">
                    <label>Num. Int </label>
                    <input
                      type="number"
                      value={numInt}
                      name="numInt"
                      disabled={tipoPersona === "" || tipoPersona === "0"}
                      onInput={(ev) => {
                        setNumInt(ev.target.value);
                      }}
                    />
                    {errors.numInt?.type === "required" && (
                      <span className="span">Este campo es requerido</span>
                    )}
                  </div>

                  {/*Estado*/}
                  <div className="col3 campo-form">
                    <label>Estado</label>
                    <input
                      type="text"
                      value={estado}
                      name="estado"
                      disabled={tipoPersona === "" || tipoPersona === "0"}
                      onInput={(ev) => {
                        setEstado(ev.target.value);
                      }}
                    />
                    {errors.estado?.type === "required" && (
                      <span className="span">Este campo es requerido</span>
                    )}
                  </div>

                  {/*Delegacion*/}
                  <div className="col3 campo-form">
                    <label>Delegaci&oacute;n</label>
                    <input
                      type="text"
                      value={estadoAux}
                      name="delegacion"
                      disabled={tipoPersona === "" || tipoPersona === "0"}
                      onInput={(ev) => {
                        setEstadoAux(ev.target.value);
                      }}
                    />
                    {errors.delegacion?.type === "required" && (
                      <span className="span">Este campo es requerido</span>
                    )}
                  </div>

                  {/*Colonia*/}
                  <div className="col3 campo-form colonia">
                    <label htmlFor="coloniaSelect">Colonia</label>
                    {error ? (
                      <input
                        type="text"
                        id="colonia"
                        value={colonia}
                        name="colonia"
                        onChange={(ev) => {
                          setColonia(ev.target.value);
                        }}
                      />
                    ) : (
                      <select
                        type="text"
                        id="colonia"
                        value={colonia}
                        name="colonia"
                        onChange={(ev) => {
                          setColonia(ev.target.value);
                        }}
                      >
                        {listColonias}
                      </select>
                    )}
                  </div>

                  <br />
                  <br />
                  <h3 className="titt">Notas</h3>
                  <hr />
                  <div id="notas"></div>

                  <div className="Resum">
                    <div className="header">
                      <h3>Documentos</h3>
                    </div>

                    <div className="cont_flex con_flex_wrap">
                      <div
                        className="cont_flex con_flex_wrap cont_subidor_archivos responsivoColumn"
                        id="documentosRenderPF"
                      ></div>
                    </div>
                  </div>

                  <div>
                    <a
                      href="/documentosVivienda"
                      className="btn"
                      onClick={(e) => {
                        e.preventDefault();
                        guardarInformacion(false);
                      }}
                    >
                      Regresar
                    </a>
                    <button  type="submit" className="btn btn_morado estiloBotonVivienda">
                      Guardar
                    </button >
                  </div>
                  <p>
                    Si realizaste un cambio recuerda guardar antes de salir.
                  </p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
