import { Navigate, Outlet, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  desencriptarToken,
  obtInformacionUsuarioSocioRemax,
} from "../utils/global_functions";
import Cookies from "universal-cookie";
import { get, getAuth } from "../utils/http";
import Swal from "sweetalert2";
import { jwtDecode } from "jwt-decode";

export const Auth = ({ session, children, path = "/" }) => {
  const location = useLocation();

  if (!session){
    const redireccion = location.pathname !== '/' ? `/?redirect=${location.pathname}` : '/';
    return <Navigate to={redireccion}></Navigate>;
  }

  return <Outlet />;
};

export const NoAuth = ({ session, children, path = "/" }) => {
  if (session) return <Navigate to="/home"></Navigate>;
  return <Outlet />;
};

export const NoAuthSocios = ({ session, children, path = "/" }) => {
  if (session) return <Navigate to="/panelSocios"></Navigate>;
  return <Outlet />;
};

export const AuthSocio = ({ session, path = "/inicioSocios" }) => {
  const [verificacionCompleta, setVerificacionCompleta] = useState(false);
  const cookie = new Cookies();

  function activarSesion(infoUser){
    //console.log(response);
    cookie.set("empresaSocio", true, { path: "/" });
    cookie.set("sessionSocio", true, { path: "/" });
    cookie.set(
      "nombreSocio",
      infoUser.nombre + " " + infoUser.paterno,
      { path: "/" }
    );
    cookie.set("correoSocio", infoUser.correo, { path: "/" });
    cookie.set("idSocio", infoUser.idSocios, { path: "/" });
  }

  const realizarVerificacion = async () => {
    try {
      // Obtener la URL actual
      const urlActual = window.location.href;

      // Obtener los parámetros de la URL
      const url = new URL(urlActual);

      // Imprime la cadena de búsqueda completa
      //console.log("Cadena de búsqueda:", url.search);

      // Obtener el valor del parámetro "token"
      const token = url.searchParams.get("token");
      //console.log(token);

      if (token) {
        cookie.remove("sessionSocio", { path: "/" });
        cookie.remove("nombreSocio", { path: "/" });
        cookie.remove("correoSocio", { path: "/" });
        cookie.remove("idSocio", { path: "/" });
        cookie.remove("empresaSocio", { path: "/" });

        let tokenDesencriptado = await desencriptarToken(token);
        //console.log(tokenDesencriptado);

        if (tokenDesencriptado !== false) {
          // Utilizar una expresión regular para extraer solo los dígitos hasta que aparezca la primera letra
          const soloDigitos = tokenDesencriptado.match(/^\d+/);

          // Si soloDigitos no es nulo, obtienes el primer elemento (que sería la coincidencia de dígitos)
          const numeros = soloDigitos ? soloDigitos[0] : null;
          //console.log(numeros);

          //let infoUser = await obtInformacionUsuarioRemax(numeros);
          let infoUser = await obtInformacionUsuarioSocioRemax(numeros);

          if (infoUser !== false) {
            activarSesion(infoUser);
          }else{ //Verificar token para otras empresas
            //console.log("Segunda verificación: ", tokenDesencriptado);
            const jsonString = tokenDesencriptado.match(/\{"[a-zA-Z]+":[0-9]+,"[a-zA-Z]+":[0-9]+\}/);

            if (jsonString[0]){
              const json = JSON.parse(jsonString[0]);

              if(json?.idUsuario && json?.idEmpresa){
                const respuesta = await get("empresa/obtInformacionUsuarioSocio", json.idUsuario);

                if (respuesta?.statuscode === 200 && respuesta?.data){
                  activarSesion(respuesta.data);
                }else if(respuesta?.statuscode === 401){
                  Swal.fire({
                    title: "Sesión agotada!",
                    text: "Crea un nuevo token para iniciar sesión",
                    icon: "error",
                    confirmButtonText: "Aceptar",
                  });
                }
              }
            }
          }
        }
      } else {
        console.error("No se encontró el parámetro 'token' en la URL");
      }
    } catch (error) {
      console.error("Error durante la verificación:", error);
    } finally {
      // Establecer verificación como completa, ya sea éxitosa o no
      setVerificacionCompleta(true);
    }
  };

  useEffect(() => {
    const cargarDatos = async () => {
      await realizarVerificacion();
      // El resto del código que deseas ejecutar después de la verificación

      if (!session && !cookie.get("sessionSocio")) {
        //console.log("entrando");
        // Solo redirigir si la verificación está completa
        setVerificacionCompleta(true);
      }
    };

    cargarDatos();
  }, []);

  if (!verificacionCompleta) {
    // Muestra un componente de carga o mensaje mientras la verificación está en curso
    return <div>Cargando...</div>;
  }

  if (!session && !cookie.get("sessionSocio")) {
    //console.log("entrando");
    return <Navigate to={path} />;
  }

  return <Outlet />;
};

export const AuthVendedor = () => {
  const cookie = new Cookies();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  function limpiarCookies(){
    cookie.remove("sessionVendedor", {path: "/documentosViviendaVendedor"});
    cookie.remove("idUsuario", {path: "/documentosViviendaVendedor"});
    cookie.remove("idResPre", {path: "/documentosViviendaVendedor"});
    cookie.remove("idCuestionario", {path: "/documentosViviendaVendedor"});
    cookie.remove("token", {path: "/documentosViviendaVendedor"});
    cookie.remove("tokenExp", {path: "/documentosViviendaVendedor"});
  }

  const realizarVerificacion = async () => {
    try {
      
      const token = queryParams.get("token");

      if (token) {
        limpiarCookies();

        let tokenDesencriptado = await desencriptarToken(token);

        if (tokenDesencriptado !== false) {
          const datos = JSON.parse(tokenDesencriptado);

          if(datos?.IdResPre && datos?.IdCuestionario && datos?.IdUsuario && datos?.Exp){
            //console.log("Sesión iniciada");
            //console.log("Exp:", datos.Exp.toString());
            
            cookie.remove("tokenExp", {path: "/documentosViviendaVendedor"});
            cookie.set("sessionVendedor", true, {path: "/documentosViviendaVendedor", expires: new Date(datos.Exp) });
            cookie.set("idUsuario", parseInt(datos.IdUsuario), {path: "/documentosViviendaVendedor" });
            cookie.set("idResPre", parseInt(datos.IdResPre), {path: "/documentosViviendaVendedor" });
            cookie.set("idCuestionario", parseInt(datos.IdCuestionario), {path: "/documentosViviendaVendedor" });
            cookie.set("token", token, {path: "/documentosViviendaVendedor" });
          }
        }
      } else {
        console.error("No se encontró el parámetro 'token' en la URL");
      }

      if(cookie.get("sessionVendedor") === undefined){
        cookie.set("tokenExp", true, {path: "/documentosViviendaVendedor"});
      }

    } catch (error) {
      console.error("Error durante la verificación:", error);
    }
  };

  useEffect(() => {
    async function inicializarProc(){
      await realizarVerificacion();
    }

    inicializarProc();
  }, []);

  /*if (!session && !cookie.get("sessionVendedor") && !cookie.get("tokenExp"))
    return <Navigate to="/"></Navigate>;*/

  return <Outlet />;
}

export const AuthEjecutivo = ({ session, sessionToken = null, rolesPermtidos = [] }) => {

  if (sessionToken){
    const tokenData = jwtDecode(sessionToken);

    if (!session || !sessionToken) {
      return <Navigate to="/inicioEjecutivo" replace={true} />;
    }

    if (rolesPermtidos && !rolesPermtidos.includes(tokenData?.rol)) {
      return <Navigate to="/inicioEjecutivo" replace={true} />;
    }

  }else{
    return <Navigate to="/inicioEjecutivo" replace={true} />;
  }

  return <Outlet/>;
};

export const NoAuthEjecutivo = ({ session, sessionToken }) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const cookies = new Cookies();
  const [verificacion, setVerificacion] = useState(false);

  async function realizarVerificacion() {
    try {
      const token = queryParams.get("token");
      if (token) {
        const peticion = await getAuth("adminEmpresa/validarToken", token, "");

        if (peticion.statuscode === 200) {
          cookies.remove("sessionEjecutivo", { path: "/" });
          cookies.remove("nombreEjecutivo", { path: "/" });
          cookies.remove("idEjecutivo", { path: "/" });
          cookies.remove("rol", { path: "/" });
          cookies.remove("token", { path: "/" });

          const tokenData = jwtDecode(peticion.respuesta);

          cookies.set("sessionEjecutivo", true, { path: "/" });
          cookies.set("nombreEjecutivo", tokenData?.nombre, { path: "/" });
          cookies.set("idEjecutivo", tokenData?.idEjecutivo, { path: "/" });
          cookies.set("rol", tokenData?.rol, { path: "/" });
          cookies.set("token", peticion.respuesta, { path: "/" });
        }
      }
    } catch (error) {
      console.error("Error durante la verificación:", error);
    }
  };

  function verificarSession(){
    const token = cookies.get("token");
    const session = cookies.get("sessionEjecutivo");

    if(session && token){
      const data = jwtDecode(token);

      switch(data?.rol){
        case "ejecutivo":
          window.location.href = "/ejecutivo/clientes";
          break;
        case "administrador_empresa":
          window.location.href = "/administradorEmpresa/invitaciones";
          break;
        case "administrador_saturn":
          window.location.href = "/administradorSaturn/roles";
          break;
        default:
          break;
      }
    }

    setVerificacion(true);
  }

  useEffect(() => {
    async function init(){
      await realizarVerificacion();
      setTimeout(verificarSession, 500);
    }

    init();
  }, []);

  if (session && sessionToken){
    const tokenData = jwtDecode(sessionToken);

    switch(tokenData?.rol){
      case "ejecutivo":
        return <Navigate to="/ejecutivo/clientes" />;
      case "administrador_empresa":
        return <Navigate to="/administradorEmpresa/invitaciones" />;
      case "administrador_saturn":
        return <Navigate to="/administradorSaturn/roles" />;
      default:
        return <Navigate to="/" />;
    }
  }

  if(verificacion){
    return <Outlet />;
  }
};