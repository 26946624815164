import "../../App.css";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Cookies } from "react-cookie";
import { Link } from "react-router-dom";
import * as ReactDOM from "react-dom/client";
import {
  TarjetaSubirDocumentacion,
  PasosAseguir,
} from "../../components/Cards";
import { NotasSolicitud, SinNotas } from "../../components/Msjs";
import { get, post, put, getCP, postDocs, putS3 } from "../../utils/http";
import Swal from "sweetalert2";
import PayPalButton from "../../components/PaypalBtns";
import { obtEmpresaUrl } from "../../utils/global_functions";

export default function PagoEvaluo() {
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [pagoAvaluoData, setPagoAvaluoData] = useState({});
  let idResPrecalifica = localStorage.getItem("idResPrecalifica");
  let idCuestionarioSolicitud = localStorage.getItem("idCuestionarioSolicitud");
  // Definimos un estado para idPantallaActual
  const [idPantallaActual, setIdPantallaActual] = useState(null);
  const [recargarDocumentos, setRecargarDocumentos] = useState(false);

  //Obtenemos la pantalla actual 20-09-23
  const obtPantallaActual = async () => {
    let dataPantalla = await get("getPantallaActual", idResPrecalifica);
    if (dataPantalla.statuscode === 200) {
      if (
        dataPantalla.infoProcesoPantalla.idCatPantallas !== null ||
        dataPantalla.infoProcesoPantalla.idCatPantallas !== ""
      ) {
        setIdPantallaActual(dataPantalla.infoProcesoPantalla.idCatPantallas);

        obtNotasPantallaActual();

        obtProgresoPantalla(dataPantalla);
      } else {
        setIdPantallaActual(0);
      }
    }
  };

  //Obtenemos las notas de la pantalla actual 20-09-23
  const obtNotasPantallaActual = async () => {
    let params = {
      idResPrecalifica: parseInt(idResPrecalifica),
      idCuestionario: parseInt(idCuestionarioSolicitud),
      idPantallaActual: 10, //Asignamos directo ya que no esta respetando la función asincrona por lo que al usar idPantallaActual no lo identifica
    };

    let dataNotasPantalla = await post("getNotas", params);

    // Obtén el div con la identificación 'notas'
    const notasDiv = document.getElementById("notas");

    // Crea una instancia de createRoot
    const rootNotas = ReactDOM.createRoot(notasDiv);

    if (dataNotasPantalla.statuscode === 200) {
      // Renderizar el contenido de dataNotasPantalla en el componente de notas
      rootNotas.render(
        <div>
          {
            <NotasSolicitud
              numNota={dataNotasPantalla.infoNotas.idNotas}
              nota={dataNotasPantalla.infoNotas.nota1}
            />
          }
        </div>
      );
    } else {
      rootNotas.render(<SinNotas></SinNotas>);
    }
  };

  //Componente de progreso
  const obtProgresoPantalla = async (dataPantalla) => {
    if (
      dataPantalla.infoProcesoPantalla.idCatPantallas != null ||
      dataPantalla.infoProcesoPantalla.idCatPantallas !== ""
    ) {
      // let nombrePantalla  = "3.Vivienda";
      // var urlActual = "/documentosVivienda";
      // let credito = localStorage.getItem("idCredito");

      // let  pantallaActualMenu = <PasosAseguir
      //     nombrePantalla ={nombrePantalla}
      //     urlActual = {urlActual}
      //     credito = {credito}
      // />
      var urlActual = "/documentosVivienda";
      let credito = localStorage.getItem("idCredito");
      let numeroPaso = "3. ";
      let descripcionPaso = "Vivienda";
      let nombrePantalla = ` ${descripcionPaso}`;
      let numeroPantalla = `${numeroPaso}`;

      let pantallaActualMenu = (
        <PasosAseguir
          numeroPantalla={
            <>
              <span className="numero-paso">{numeroPantalla}</span>
            </>
          }
          nombrePantalla={
            <span className="descripcion-paso">{nombrePantalla}</span>
          }
          urlActual={urlActual}
          credito={credito}
        />
      );

      const root1 = ReactDOM.createRoot(
        document.getElementById("MenuRenderizado")
      );
      root1.render(pantallaActualMenu);
    }
  };

  const [pagoAvaluo, setPagoAvaluo] = useState({
    documento: null, // Estado para almacenar el archivo seleccionado
  });

  useEffect(() => {
    const cargarDatos = async () => {
      try {
        await obtEmpresaUrl();
        setIsLoading(false);
      } catch (error) {
        console.error("Error al cargar datos:", error);
        setIsLoading(false);
      }
    };

    cargarDatos();
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    obtInformacion();
    obtDocumentos();
    obtPantallaActual();
  }, []);

  useEffect(()=>{
    if (recargarDocumentos){
      obtDocumentos();
      setRecargarDocumentos(false);
    }
  }, [recargarDocumentos]);

  /*const handleDocumentoChange = (event) => {
        const selectedFile = event.target.files[0];
        setPagoAvaluo((prevInfo) => ({
            ...prevInfo,
            documento: selectedFile,
        }));
    };*/
  const handleDocumentoChange = async (event, errorFunctions = () => {}) => {
    const selectedFile = event.target.files[0];
    const inputName = event.target.name;
    //console.log("Archivo seleccionado:", selectedFile); // Verifica si muestra el archivo en la consola

    if (selectedFile) {
      setPagoAvaluo((prevInfo) => ({
        ...prevInfo,
        documento: selectedFile,
      }));
      try {
        const peticionArchivoS3 = await post("S3/subirArchivoUrl", {
          nombreArchivo: selectedFile.name,
        });

        if (peticionArchivoS3?.statuscode === 200) {
          const peticionS3 = await putS3(peticionArchivoS3.respuesta.url, selectedFile);

          if(peticionS3?.ok){
            const insertDocumento = await post("subirDocumento", {
              rutaArchivo: peticionArchivoS3.respuesta.rutaArchivo,
              idCatDocumento: inputName,
              idCuestionario: idCuestionarioSolicitud,
              idResPrecalifica: idResPrecalifica,
            });

            if (insertDocumento?.statuscode === 200) {
              setRecargarDocumentos(true);
            } else {
              errorFunctions();
              Swal.fire({
                title: "Datos ingresados erroneos",
                text: "Verifica que tus datos sean correctos ",
                icon: "error",
                confirmButtonText: "Aceptar",
              });
            }
          }
        }
      } catch (error) {
        // Maneja errores aquí
      }
    } else {
      // console.log("No se seleccionó ningún archivo");
    }
  };

  const guardarInformacion = async () => {
    /*const formData = new FormData();
        formData.append('Archivo', pagoAvaluo.documento);
        formData.append('IdCatDocumento', 13); //Hay que corregir, debe ser pago avaluo comprobante y no otros documentos
        //formData.append('IdCuestionario', 134);
        //formData.append('IdResPrecalifica', 294);
        formData.append('IdCuestionario', idCuestionarioSolicitud);
        formData.append('IdResPrecalifica', idResPrecalifica);

        
        let insertDocumento = await postDocs("subirDocumento", formData);
        if(insertDocumento.statuscode === 200){
            Swal.fire({
                title: 'Datos guardados correctamente',  
                icon: 'success',
                confirmButtonText: 'Aceptar'
            })

            navigate("/avaluo");
        } else {
            Swal.fire({
                title: 'Datos ingresados erroneos',
                text: 'Verifica que tus datos sean correctos ',
                icon: 'error',
                confirmButtonText: 'Aceptar'
            })
        }*/
    navigate("/documentosVivienda");
  };

  //Formatear números a pesos
  const GetNF = (value) => {
    // console.log(value);
    if (value !== undefined) {
      return value.toLocaleString("es-MX", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    } else {
      return 0;
    }
  };

  //Obtenemos información general
  const obtInformacion = async () => {
    let data = await get("getPagoAvaluo", idResPrecalifica);
    //console.log(data);
    if (data.statuscode === 200) {
      localStorage.setItem("pagoAvaluo", data.avaluo[0]['monto'] );
      setPagoAvaluoData(() => ({
        ...pagoAvaluoData,
        ...data.avaluo[0],
      }));
    } else {
      //en caso de no tener informacion esto se ejecutara
      //console.log("Sin informacion");
    }
  };

  //Para verificar si ya tiene un documento
  const verificarDocumento = async (idCatDocumento) => {
    let params = {
      idResPrecalifica: parseInt(idResPrecalifica),
      idCuestionario: parseInt(idCuestionarioSolicitud),
      idCatDocumento: idCatDocumento,
    };

    let verificarDocumento = await post("getInfoDocumento", params);
    if (verificarDocumento.statuscode === 200) {
      let cadena = verificarDocumento.value.archivo;
      // Dividir la cadena por las barras invertidas para obtener partes
      const partes = cadena.split("/");

      // Obtener la última parte que contiene el nombre de archivo y extensión
      const nombreArchivoConExtension = partes[partes.length - 1];

      return {
        nombreDoc: nombreArchivoConExtension,
        info: verificarDocumento.info,
      };
    } else {
      return verificarDocumento.info;
    }
  };

  //Para obtener todos los documentos
  var docs;
  const obtDocumentos = async () => {
    // Crear el root para los documentos una sola vez fuera de la función obtDocumentos
    const rootDocumentos = ReactDOM.createRoot(
      document.getElementById("documentosRender")
    );
    let data = await get("getDocumentosByPantalla", "4");
    if (data.statuscode === 200) {
      let documentos = [];
      let arrDocsCat = data.documentos[0].documentos;
      //console.log(arrDocsCat);
      // Crear un array de promesas para verificar los documentos
      const verificarPromises = arrDocsCat.map(async (documento) => {
        try {
          let resVerDoc = await verificarDocumento(documento.idCatDocumentos);
          return {
            titulo: documento.nombre,
            parrafo:
              documento.idCatDocumentos === "3" ||
              documento.idCatDocumentos === "4"
                ? "Nota de recibo"
                : "",
            link: documento.idCatDocumentos !== "2" ? false : true,
            enlace:
              documento.idCatDocumentos !== "2"
                ? ""
                : "https://saturn5.mx/contrato-ejemplo.docx",
            onDocumentoChange: { handleDocumentoChange },
            tipoDoc: documento.idCatDocumentos,
            eventoBorrar: { borrarDocumento },
            docVerificado: resVerDoc.info, // Guardar el resultado de la verificación
            nombreDocumento: resVerDoc.nombreDoc,
          };
        } catch (error) {
          console.error("Error al verificar el documento", error);
          return null;
        }
      });

      // Esperar a que todas las promesas se completen
      const documentosWithVerificaciones = await Promise.all(verificarPromises);

      // Filtrar los resultados nulos en caso de errores
      const documentosValidos = documentosWithVerificaciones.filter(
        (doc) => doc !== null
      );

      if (documentosWithVerificaciones.length === 0) {
        // console.log("No hay documentos");
      } else {
        docs = documentosWithVerificaciones.map((documento) => (
          <TarjetaSubirDocumentacion
            titulo={documento.titulo}
            parrafo={documento.parrafo}
            link={documento.link}
            enlace={documento.enlace}
            onDocumentoChange={handleDocumentoChange}
            tipoDoc={documento.tipoDoc}
            del={true}
            handleClick={handleClick}
            documentoSubido={documento.docVerificado}
            nombreDocumento={documento.nombreDocumento}
          />
        ));

        rootDocumentos.render(docs);
      }
    }
  };

  const borrarDocumento = async (event) => {
    const selectedFileName = event;
    //console.log(selectedFileName);
  };

  //Para borrar el archivo en caso de existir
  const handleClick = () => {
    //console.log('El componente fue clicado');
    // Puedes realizar cualquier acción que desees aquí
  };

  return (
    <>
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
          <div className="loading-text">Cargando...</div>
        </div>
      )}
      <div className=" cont_Info">
        <Link to="/documentosVivienda" className="link linkGrande back">
          {" "}
          {"<"} Regresar a informaci&oacute;n vivienda
        </Link>

        <div id="MenuRenderizado" className="MenuRenderizado"></div>

        <h1>Pago de aval&uacute;o</h1>
        <p>
          Es necesario pagar el aval&uacute;o, puedes pagarlo siguiendo las
          instrucciones de pago y anexando el comprobante de pago o si lo
          prefieres puedes pagarlo directamente aqu&iacute; utilizando tu
          tarjeta de d&eacute;bito o cr&eacute;dito.
        </p>

        <div className="cont_flex con_flex_wrap resumen">
          <h3>Instrucciones de Pago de Avaluo</h3>

          <div className="bg_gris">
            <p>{pagoAvaluoData.instruccionesPagoAvaluo}</p>
          </div>

          <div className="bg_gris">
            <p>Monto</p>
            <p>{"$" + GetNF(parseFloat(pagoAvaluoData.monto))}</p>
          </div>
        </div>

        <div className="cont_flex con_flex_wrap pagoEvaluo">
          {/*<TarjetaSubirDocumentacion titulo = {"Comprobante de pago"} parrafo = {"Te recomendamos escanearlo. Si es una foto, asegurate que sea de buena calidad y legible"} onDocumentoChange={handleDocumentoChange}></TarjetaSubirDocumentacion>
           */}
          <div id="documentosRender"></div>

          <div className="cont_loop bg_gris">
            <h3>Si lo prefieres, puedes hacer el pago en linea</h3>
            {/*<a href="#" className="btn btn_morado">Pagar en linea</a>*/}
            <PayPalButton precio={pagoAvaluoData.monto} />
          </div>
        </div>

        <h3 className="titt">Notas</h3>

        <div id="notas"></div>

        <a href="/documentosVivienda" className="btn ">
          Regresar
        </a>

        <a
          onClick={() => {
            guardarInformacion();
          }}
          className="btn btn_morado"
        >
          Guardar
        </a>

        <p>Si realizaste un cambio recuerda guardar antes de salir.</p>
      </div>
    </>
  );
}
