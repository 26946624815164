import React, { useState, useEffect, useRef } from "react";
import { obtEmpresaUrl } from "../../../utils/global_functions.js";
import { TablaClientesEjecutivo } from "../../../components/Tablas.jsx";
import { get } from "../../../utils/http.js";
import { jwtDecode } from "jwt-decode";
import { Cookies } from "react-cookie";

export default function ClientesEjecutivo() {
  const [isLoading, setIsLoading] = useState(true);
  const [clientes, setClientes] = useState([]);
  const cookies = new Cookies();
  const tokenEjecutivo = useRef(cookies.get("token") ? jwtDecode(cookies.get("token")) : null);

  useEffect(() => {
    const cargarDatos = async () => {
      try {
        await obtEmpresaUrl();

        if(tokenEjecutivo.current){
          const peticion = await get("obtUltimosClientes", tokenEjecutivo.current?.idEmpresa ?? "");
          if (peticion?.statuscode === 200){
            setClientes(peticion.respuesta);
          }
        }

        setIsLoading(false);
      } catch (error) {
        console.error("Error al cargar datos:", error);
      }
    };

    cargarDatos();
  }, []);

  return (
    <>
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
          <div className="loading-text">Cargando...</div>
        </div>
      )}

      <div className="cont_Info">
        <h1 className="titt">Clientes</h1>
        <p className="descripcionClientes">
          Aquí puedes conocer las capacidades de crédito de tus clientes y la etapa en la que se encuentran en su crédito
        </p>

        <div className="tabla_clientes">
          <TablaClientesEjecutivo clientes={clientes}/>
        </div>
        <br/>

      </div>
    </>
  );
}