import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Cookies } from "react-cookie";
import { post } from "../../../utils/http";
import Swal from "sweetalert2";
import { obtEmpresaUrl } from "../../../utils/global_functions";
import { useForm } from "react-hook-form";
import { ruleEmail } from "../../../assets/validateRules";
import { jwtDecode } from "jwt-decode";

export default function NuevaInvitacion({paginaAnterior = -1}) {
  const [isLoading, setIsLoading] = useState(true);
  const cookies = new Cookies();
  const navigate = useNavigate();
  const tokenEjecutivo = useRef(jwtDecode(cookies.get("token")));

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({});

  useEffect(() => {
    const cargarDatos = async () => {
      try {
        await obtEmpresaUrl();
        setIsLoading(false);
      } catch (error) {
        console.error("Error al cargar datos:", error);
        setIsLoading(false);
      }
    };

    cargarDatos();
  }, []);

  const enviar = async (data) => {
    if(tokenEjecutivo.current?.idSocio){
      let dataInvitacionCliente = {
        idSocios: tokenEjecutivo.current.idSocio,
        nombre: data.nombre,
        paterno: data.paterno,
        materno: data.materno,
        telefono: data.telefono,
        correo: data.email,
        estatus: 1,
      };
      let insertData = await post("newInvitacion", dataInvitacionCliente);
  
      if (insertData.statuscode === 200) {
        Swal.fire({
          title: "Invitación enviada correctamente",
          icon: "success",
          confirmButtonText: "Aceptar",
        }).then(() => {
          navigate(paginaAnterior);
        });
      } else {
        Swal.fire({
          title: "Datos ingresados erroneos",
          text: "Verifica que tus datos sean correctos ",
          icon: "error",
          confirmButtonText: "Aceptar",
        });
      }
    }else{
      Swal.fire({
        title: "Error",
        text: "No tiene asociada una cuenta de socio",
        icon: "error",
        confirmButtonText: "Aceptar",
      });
    }
  };

  return (
    <>
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
          <div className="loading-text">Cargando...</div>
        </div>
      )}
      <div className=" cont_Info">
        <h1 className="titt">Ingresa los datos de tu cliente​</h1>
        <p>
          Ingresa los datos de tu cliente y enviaremos una notificaci&oacute;n
          para que inicie su proceso de preautorizaci&oacute;n de
          cr&eacute;dito.
        </p>

        <form onSubmit={handleSubmit(enviar)}>
          <div className="Resum">
            <div className="cont_flex cont-form">
              {/* NOMBRE */}
              <div className="col3 campo-form ">
                <label>Nombre(s)</label>
                <input
                  type="text"
                  id="nombre"
                  name="nombre"
                  {...register("nombre", {required: true})}
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/[^A-Za-zÁÉÍÓÚáéíóúÜüÑñ ]/g, "");
                  }}
                />
                {errors.nombre?.type === "required" && (
                  <small style={{ color: "red" }}>Este campo es requerido</small>
                )}
              </div>

              {/* APELLIDO PATERNO */}
              <div className="col3 campo-form">
                <label>Apellido Paterno</label>
                <input
                  type="text"
                  id="paterno"
                  name="paterno"
                  {...register("paterno", {required: true})}
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/[^A-Za-zÁÉÍÓÚáéíóúÜüÑñ ]/g, "");
                  }}
                />
                {errors.paterno?.type === "required" && (
                  <small style={{ color: "red" }}>Este campo es requerido</small>
                )}
              </div>

              {/* APELLIDO MATERNO */}
              <div className="col3 campo-form">
                <label>Apellido Materno</label>
                <input
                  type="text"
                  id="materno"
                  name="materno"
                  {...register("materno", {required: true})}
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/[^A-Za-zÁÉÍÓÚáéíóúÜüÑñ ]/g, "");
                  }}
                />
                {errors.materno?.type === "required" && (
                  <small style={{ color: "red" }}>Este campo es requerido</small>
                )}
              </div>

              {/* TEL*/}
              <div className="col3 campo-form">
                <label>Teléfono</label>
                <input
                  type="tel"
                  id="telefono"
                  name="telefono"
                  minLength={10}
                  maxLength={10}
                  {...register("telefono", { required: true })}
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/[^0-9]/g, "");
                  }}
                />
                {errors.telefono?.type === "required" && (
                  <small style={{ color: "red" }}>Este campo es requerido</small>
                )}
              </div>

              {/* CORREO*/}
              <div className="col3 campo-form">
                <label>Correo electrónico</label>
                <input
                  type="email"
                  id="correo"
                  name="correo"
                  maxLength={50}
                  {...register("email", { required: true, validate: (email) => ruleEmail(email) })}
                />
                {errors.email?.type === "required" ? (
                  <small style={{ color: "red" }}>Este campo es requerido</small>
                ) : (
                  errors.email?.type === "validate" && (
                    <small style={{ color: "red" }}>Formato no válido</small>
                  )
                )}
              </div>
            </div>
          </div>

          <div className="cont_flex cont-form">
            <div className="dosCuttonFormularios">
              <button
                type="button"
                className="btn btn_bco col3 campo-form"
                onClick={() => {
                  navigate(paginaAnterior);
                }}
              >
                Regresar
              </button>
              <button className="btn btn_morado col3 campo-form" type="submit">
                Enviar
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
