import React, { useState, useEffect } from "react";
import { obtEmpresaUrl } from "../../../utils/global_functions.js";
import { get } from "../../../utils/http.js";
import { ArchivoNoEditable } from "../../../components/ArbolArchivos.jsx";

export default function DocumentosEjecutivo() {
  const [isLoading, setIsLoading] = useState(true);
  const [archivos, setArchivos] = useState([]);

  useEffect(() => {
    const cargarDatos = async () => {
      try {
        await obtEmpresaUrl();
        await cargarArchivosCompartidos();
        setIsLoading(false);
      } catch (error) {
        console.error("Error al cargar datos:", error);
      }
    };

    cargarDatos();
  }, []);

  async function cargarArchivosCompartidos(){
    const peticion = await get("adminSaturn/obtArchivosCompartidos", "");
    if(peticion?.statuscode === 200){
      setArchivos([peticion.respuesta]);
    }
  }

  return (
    <>
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
          <div className="loading-text">Cargando...</div>
        </div>
      )}

      <div className="cont_Info">
        <h1 className="titt">Documentos</h1>
        <p className="descripcionClientes">
          Visualiza los archivos compartidos por el administrador
        </p>

        <div style={{height: "380px"}}>
          <div id="arbol-archivos">
            <ul>
              {archivos?.map((archivo) => (
                <ArchivoNoEditable key={archivo.nombre} archivo={archivo} actualizarArchivos={setArchivos} />
              ))}
            </ul>
          </div>
        </div>
        <br/>
        
      </div>
    </>
  );
}